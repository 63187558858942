import {
  NETWORK_SUCCESS,
  GET_CURRENT_MOVEPLAN,
  GET_VALID_DATES,
  UPDATE_MOVE_PLAN,
  UPDATE_BOX_PURCHASES,
  UPDATE_PACKING_SERVICES,
  UPDATE_MOVE_DATE,
  CHECK_VALID_DATES,
  CHECK_MOVER_AVAILABILITY,
  EMAIL_PLAN,
  EMAIL_LEAD,
  NETWORK_FAILURE,
  ADDRESS_UPDATE, 
  REQUEST_INSURANCE, 
  CANCEL_MOVE, 
  CREATE_MOVEPLAN, 
  SUBMIT_DNA, 
  SET_CURRENT_MOVEPLAN_ID, 
  CONTINUE_LAST_PLAN, 
  SUBMIT_UNSERVED_EMAIL, 
  UPDATE_MOVE_TIME,
  CHANGE_MOVER,
  UNSELECT_MOVER,
  SUBMIT_DNA_AFFILIATE,
  UPDATE_NUM_LABORERS,
  UPDATE_NUM_HOURS,
  UPDATE_TRUCK_SIZE,
  UPDATE_NUM_UPRIGHTS,
  UPDATE_HOURLY_STAIRS,
  UPDATE_NUM_BABY_GRAND,
  UPDATE_NUM_HOURLY_OVERWEIGHT,
  UPDATE_NUM_HOURLY_OVERSIZE
} from '../actionTypes';
import {apiRequest} from "../actions/api";
import {
  clearBoxPurchases,
  getCurrentMoveplan, getValidDates,
  setCurrentPlanInfo, setMoveplanInsurance,
  setValidDates, updateBoxPurchases,
  updateMovePlan, setCurrentMoveplanID, cancelMove, unselectMover, removeHourlyFlag
} from '../actions/movePlan';
import { openModal, closeAllModals, closeModal } from '../actions/modals';
import { setEmailError, setEmailSent, setPlanUpdating, initPlanFinished } from '../actions/plan';
import { push } from "connected-react-router";
import { setDetailsLoading } from '../actions/details';
import has from 'lodash-es/has';
import { setInventoryLoading, setAddedTypicals } from '../actions/inventory';
import { hasBoxPurchases, isValidMovedate } from '../reducers/movePlan';
import { createMovePlan, setMoveplanLoading, setDnaError, setHourlyRenderLoading } from '../actions/movePlan';
import { track } from '../actions/analytics';
import { HOME_SIZES } from '../../constants';
import { setLastMPID } from '../actions/auth';
import { setLastStep, resetFunnel, hourlyResetFunnel, importFunnelState, nextStep, gotoCompare, gotoPlan, initHourlyLastStep } from '../actions/funnel';
import { getBids, setCompareLoading } from '../actions/compare';
import { setPrevPickUpAddress, setPrevDropOffAddress} from '../actions/common'

const movePlanMiddleware = ({ getState, dispatch }) => next => action => {
  next(action);

  if (action.type === SET_CURRENT_MOVEPLAN_ID) {
    //dispatch(setLastMPID(action.payload.movePlanId));
  }

  if (action.type === CREATE_MOVEPLAN) {
    dispatch(setMoveplanLoading(true));
    dispatch(setAddedTypicals(false));
    let payload = action.payload
    if(has(getState().analytics, 'code')) {
      payload.promo_code = getState().analytics.couponData.code
    }
    dispatch(setPrevPickUpAddress(action.payload.pick_up_address))
    dispatch(setPrevDropOffAddress(action.payload.drop_off_address))
    dispatch(apiRequest({
      url: `${process.env.REACT_APP_API_URL}/move_plans`,
      method: 'POST',
      params: {
        move_plan: payload
      },
      fromAction: CREATE_MOVEPLAN
    }))
  }

  if (action.type === GET_CURRENT_MOVEPLAN) {    
    dispatch(setMoveplanLoading(true));
    const mpid = !!action.meta.useLast ? getState().auth.lastMPID : getState().movePlan.currentMPID;
    dispatch(
      apiRequest({
        url: `${process.env.REACT_APP_API_URL}/move_plans/${mpid}.json`,
        method: 'GET',
        fromAction: GET_CURRENT_MOVEPLAN,
        redirect: has(action.meta, 'redirect') ? action.meta.redirect : false 
      })
    );
  }
  if (action.type === GET_VALID_DATES) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/valid_dates`,
        method: 'GET',
        params: {
          move_date: getState().movePlan.currentPlan.details.move_date
        },
        fromAction: GET_VALID_DATES
      })
    );
  }

  if (action.type === UPDATE_MOVE_PLAN) {
    let updateParams = {};
    for( let key in action.payload) {
      updateParams[key] = action.payload[key];
    }
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}`,
        method: 'PATCH',
        params: updateParams,
        fromAction: UPDATE_MOVE_PLAN,
        redirect: !!action.meta ? action.meta : false
      })
    )
  }

  if (action.type === UPDATE_BOX_PURCHASES) {
    
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/update_box_delivery`,
        method: 'POST',
        params: {
          boxes: action.payload.boxPurchases,
          delivery_date: action.payload.date
        },
        fromAction: UPDATE_BOX_PURCHASES
      })
    );
  }

  // if (action.type === REMOVE_BOX_PURCHASES) {
  //   let temp = getState().movePlan.currentPlan.box_purchases;
  //   temp.map( boxPurchase => {
  //     boxPurchase.quantity = 0;
  //     return true;
  //   });
  //   dispatch(
  //     updateMovePlan({
  //       move_plan: {
  //         box_delivery_date: null
  //       }
  //     })
  //   );
  // }
  if (action.type === UPDATE_PACKING_SERVICES) {
    // 1= packing
    // 2= packing & unpacking
    // 4= pack yourself
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/update_packing`,
        method: 'PATCH',
        params: {
          follow_up_packing_service_id: action.payload
        },
        fromAction: UPDATE_PACKING_SERVICES
      })
    );
  }
  if (action.type === UPDATE_MOVE_DATE) {
    dispatch(setCompareLoading(true));
    dispatch(
      updateMovePlan({
        move_plan: {
          move_date: action.payload.move_date
        }
      })
    )
  }

  if (action.type === UPDATE_MOVE_TIME) {
    dispatch(setCompareLoading(true));
    dispatch(
      updateMovePlan({
        move_time: action.payload.move_time
      })
    )
  }
  if (action.type === CHECK_VALID_DATES) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/valid_dates`,
        method: 'GET',
        params: {
          move_date: action.payload
        },
        fromAction: CHECK_VALID_DATES
      })
    );
  }
  if (action.type === CHECK_MOVER_AVAILABILITY) {
    dispatch(setDetailsLoading(true));
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/estimates/for_move_dates`,
        method: 'GET',
        params: action.payload,
        fromAction: CHECK_MOVER_AVAILABILITY
      })
    );
  }

  if (action.type === EMAIL_PLAN) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/email`,
        method: 'POST',
        params: { email: action.payload },
        fromAction: EMAIL_PLAN
      })
    );
  }

  if (action.type === EMAIL_LEAD) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/email_lead`,
        method: 'POST',
        params: { email: action.payload },
        fromAction: EMAIL_LEAD
      })
    );
  }

  if (action.type === ADDRESS_UPDATE) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}`,
        method: 'PATCH',
        params: {
          address_update: true,
          move_plan: {
            ...getState().movePlan.currentPlan.details,
            [action.payload.addressKey]: action.payload.route
          }
        },
        fromAction: ADDRESS_UPDATE
      })
    )
  }

  if (action.type === REQUEST_INSURANCE) {
    dispatch(setPlanUpdating(true));
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/request_insurance`,
        method: 'POST',
        fromAction: REQUEST_INSURANCE
      })
    )
  }

  if(action.type === CANCEL_MOVE) {
    dispatch(closeAllModals());
    dispatch(openModal({ name: 'loader', properties: { state: 'initial' }}));
    dispatch(
     apiRequest({
       url: `api/v3/move_plans/${getState().movePlan.currentMPID}/cancel_job`,
       method: 'POST',
       fromAction: CANCEL_MOVE,
       params: {message: action.payload}
     })
   )
  }

  if(action.type === SUBMIT_DNA) {
    if(getState().auth.lastMPID !== '' && getState().modals.length === 0) {
      dispatch(openModal({ name: 'continueLastPlan', properties: {position:'top'}}));
      dispatch(getCurrentMoveplan({ useLast: true }));
    } else {
      if(getState().ui.mobile.isMobile && action.payload.source !== 'Concierge') action.payload.source = 'Aqua Moving';
      dispatch(setMoveplanLoading(true));
      dispatch(track('Move plan started', {
        extra_pick_up: !!action.payload.extra_pick_up_enabled,
        extra_drop_off: !!action.payload.extra_drop_off_enabled,
        home_size: HOME_SIZES[action.payload.home_size_id].type,
        added_storage: action.payload.storage_in_transit || action.payload.move_into_storage,
        created_device_type: getState().ui.mobile.isMobile ? "mobile" : "desktop"
      }));
      if (action.payload.hourly_plan && action.payload.hourly_plan === true) {
        dispatch(openModal({ name: 'loader', properties: { state: 'initial_hourly' }}))  
      } else {
        dispatch(openModal({ name: 'loader', properties: { state: 'initial' }}))
      }      
      if(Object.keys(getState().analytics.params).length > 0) {
        let params = getState().analytics.params;
        if(!!params.utm_source) action.payload.source = params.utm_source
        if(!!params.promo) action.payload.promo_code = params.promo
        if(!!params.utm_content) action.payload.advance_referrer = params.utm_content
        if(!!params.gclid) action.payload.source = "Paid-Google"
        if(!!params.fcblid) action.payload.source = "Paid-Facebook"
      }
      if(getState().ui.mobile.isMobile) action.payload.created_device_type = "mobile"
      else action.payload.created_device_type = "desktop"
      if(!!getState().analytics.couponData) {
        action.payload.promo_code = getState().analytics.couponData.code
      }
      dispatch(createMovePlan(action.payload));
      if(!!action.payload.hourly_plan){
        dispatch(hourlyResetFunnel());
      }
      else {
        dispatch(resetFunnel());
      }
      sessionStorage.setItem('addedTypicals', false);
    }
  }

  if(action.type === SUBMIT_DNA_AFFILIATE) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/partners_create`,
        method: 'POST',
        params: { 
          data: action.payload
        },
        fromAction: SUBMIT_DNA_AFFILIATE
      })
    );
  }


  if(action.type === CONTINUE_LAST_PLAN) {
    dispatch(openModal({ name: 'loader', properties: { state: 'initial' }}));
    dispatch(getCurrentMoveplan({ redirect: 'last_step'}));
  }

  if(action.type === SUBMIT_UNSERVED_EMAIL) {
    dispatch(setMoveplanLoading(true));
    dispatch(
      apiRequest({
        url: `api/v3/unserved_users`,
        method: 'POST',
        params: { 
          move_plan_id: getState().movePlan.currentMPID,
          unserved_user: { email: action.payload }
        },
        fromAction: SUBMIT_UNSERVED_EMAIL
      })
    );
  }

  // if(action.type === SUBMIT_RESCHEDULE_REQUEST) {
  //   dispatch(checkMoverAvailability(params));
  //   if (!!this.props.services.box_delivery) {
  //     if (this.props.validDates.is_at_least_5_business_days_away){
  //       if (this.isBoxDeliveryDateValid()){
  //         this.props.updateMovePlan(params);
  //       } else { //box delivery needs changing
  //         if (this.boxDeliveryNeedsUpdate()) {
  //           params.box_delivery_date = this.props.validDates.max_box_delivery_date;
  //           this.props.updateMovePlan(params);
  //         } else this.props.updateMovePlan(params);
  //       }
  //     } else { //box delivery needs changing
  //       params.box_delivery_date = this.props.validDates.max_box_delivery_date;
  //       this.props.updateMovePlan(params);
  //     }
  //   } else this.props.updateMovePlan(params);
  //   this.props.closeModal()
  // }

  if (action.type === CHANGE_MOVER) {
    const updateMp = () => new Promise(resolve => {
      dispatch(updateMovePlan(action.payload));
      resolve('resolved')
    })
    const waitForUpdateMp = async () => {
      //eslint-disable-next-line
      let d = await updateMp();
      dispatch(gotoCompare());
    }
    if(getState().movePlan.currentPlan.is_booked) {
      const cancelJob = () => new Promise(resolve => {
        dispatch(cancelMove());
        resolve('resolved');
      });
      const waitForCancel = async () => {
        //eslint-disable-next-line
        let a = await cancelJob();
        waitForUpdateMp();
      }
      waitForCancel();
    } else {
      const unselect = () => new Promise(resolve => {
        dispatch(unselectMover());
        resolve('resolved')
      })
      const waitForUnselect = async () => {
        //eslint-disable-next-line
        let c = await unselect();
        waitForUpdateMp();
      }
      waitForUnselect();
    }
  }

  if(action.type === UNSELECT_MOVER ) {
    dispatch(setMoveplanLoading(true));
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/unchoose_mover`,
        method: 'PATCH',
        fromAction: UNSELECT_MOVER
      })
    );
  }



  if (action.type === UPDATE_TRUCK_SIZE) {    
    dispatch(setCompareLoading(true));
    dispatch(
      updateMovePlan({
        move_plan: {
          truck_size_id: action.payload
        }
      })
    )
  }

  if (action.type === UPDATE_NUM_HOURS) {
    dispatch(setCompareLoading(true));
    dispatch(
      updateMovePlan({
        move_plan: {
          num_hours: action.payload
        }
      })
    )
  }

  if (action.type === UPDATE_NUM_LABORERS) {
    dispatch(setCompareLoading(true));
    dispatch(
      updateMovePlan({
        move_plan: {
          num_hourly_laborers: action.payload
        }
      })
    )
  }

  if (action.type === UPDATE_NUM_UPRIGHTS) {
    dispatch(setCompareLoading(true));
    dispatch(
      updateMovePlan({
        move_plan: {
          num_hourly_upright_pianos: action.payload
        }
      })
    )
  }

  if (action.type === UPDATE_NUM_BABY_GRAND) {
    dispatch(setCompareLoading(true));
    dispatch(
      updateMovePlan({
        move_plan: {
          num_hourly_baby_grand_pianos: action.payload
        }
      })
    )
  }

  if (action.type === UPDATE_NUM_HOURLY_OVERWEIGHT) {
    dispatch(setCompareLoading(true));
    dispatch(
      updateMovePlan({
        move_plan: {
          num_hourly_overweight: action.payload
        }
      })
    )
  }

  if (action.type === UPDATE_NUM_HOURLY_OVERSIZE) {
    dispatch(setCompareLoading(true));
    dispatch(
      updateMovePlan({
        move_plan: {
          num_hourly_oversize: action.payload
        }
      })
    )
  }

  if (action.type === UPDATE_HOURLY_STAIRS) {
    dispatch(setCompareLoading(true));
    dispatch(
      updateMovePlan({
        move_plan: {
          num_hourly_stairs: (action.payload === 'true') ? 1 : 0
        }
      })
    )
  }

  if (action.type === NETWORK_SUCCESS) {    
    switch (action.meta.originalRequest.fromAction) {
      case SUBMIT_DNA_AFFILIATE:
        window.top.location.href = `${process.env.REACT_APP_BASE_URL}${action.payload.response.data.path}`
        break;
      case CREATE_MOVEPLAN:
        const resp = action.payload.response.data;
        if(!!resp.uuid) {
          dispatch(track('Move Plan', {uuid: resp.uuid}));
          dispatch(setCurrentMoveplanID(resp.uuid));
          dispatch(setLastMPID(resp.uuid));
          if(!!resp.hourly_plan)
          {
            dispatch(hourlyResetFunnel());
          } else {            
            dispatch(resetFunnel());
          }
        } else {
          //dispatch(setLastMPID(null));
        }
          
        if (!resp.hourly_movers_found && !resp.movers_found) {
          dispatch(setCurrentMoveplanID(''));
          dispatch(setLastMPID(''));
          dispatch(setDnaError(resp));
          dispatch(closeAllModals());
        } else if (!resp.hourly_plan && !resp.movers_found) {
          // no movers for regular plan
          dispatch(setCurrentMoveplanID(''));
          dispatch(setLastMPID(''));
          dispatch(closeModal());
          dispatch(setMoveplanLoading(false))
          dispatch(openModal({name: 'useHourlyPlanTab'}))          
        } else if (resp.hourly_plan && !resp.hourly_movers_found) {
          dispatch(setCurrentMoveplanID(''));
          dispatch(setLastMPID(''));
          dispatch(closeModal());
          dispatch(setMoveplanLoading(false))
          dispatch(openModal({name: 'useRegularPlanTab'}))
        } else {
          if ((!resp.hourly_plan && !resp.movers_all_consult_only) || (resp.hourly_plan && !resp.hourly_movers_all_consult_only)) {
            if (getState().ui.mobile.isMobile && !resp.hourly_plan) {
              window.location.href = `${process.env.REACT_APP_MOBILE_FUNNEL_URL}/moving/${resp.uuid}/overview`
            } else {
              dispatch(setMoveplanLoading(false))
              dispatch(closeAllModals());
              if(!!action.payload.response.data.hourly_plan){
                dispatch(initHourlyLastStep());
                dispatch(push(`/moving/${action.payload.response.data.uuid}/compare`));
              }else{
                if (getState().movePlan.currentPlan.hourly_plan) {
                  dispatch(removeHourlyFlag())
                }
                dispatch(push(`/moving/${action.payload.response.data.uuid}/inventory`));
              }
            }
          } else {
            dispatch(setCurrentMoveplanID(action.payload.response.data.uuid))
            dispatch(getCurrentMoveplan());
            dispatch(setDnaError(resp));
            dispatch(closeAllModals());
          }
        }
        break;   
        
        // if(resp.movers_found && !resp.movers_all_consult_only) {
        //   if(getState().ui.mobile.isMobile && !resp.hourly_plan) {
        //     window.location.href = `${process.env.REACT_APP_MOBILE_FUNNEL_URL}/moving/${resp.uuid}/overview`
        //   } else {
        //     dispatch(setMoveplanLoading(false))
        //     dispatch(closeAllModals());
        //     if(!!action.payload.response.data.hourly_plan){
        //       dispatch(initHourlyLastStep());
        //       dispatch(push(`/moving/${action.payload.response.data.uuid}/compare`));
        //     }else{
        //       dispatch(push(`/moving/${action.payload.response.data.uuid}/inventory`));
        //     }
        //   }
        // } else {          
        //   dispatch(setCurrentMoveplanID(action.payload.response.data.uuid))
        //   dispatch(getCurrentMoveplan());
        //   dispatch(setDnaError(resp));
        //   dispatch(closeAllModals());
        // }     
        // break;

      case UPDATE_MOVE_PLAN:                
        dispatch(setCurrentPlanInfo(action.payload.response.data.plan));
        dispatch(getValidDates());
        if(getState().funnel.currentStep === 'COMPARE') {
          dispatch(getBids());
        }
        if(!!action.meta.originalRequest.redirect) {
          dispatch(nextStep());
        }
        dispatch(setCompareLoading(false)); 
        dispatch(setPlanUpdating(false));
        dispatch(closeModal());
        break;
      case GET_CURRENT_MOVEPLAN:        
        //prevent users from editing mp after move is completed
        if(!!action.payload.response.data.plan.is_done && !!!getState().auth.isAdmin) {
          const step = getState().funnel.currentStep;
          if(step === "INVENTORY" || step === "DETAILS" || step === "COMPARE") {
            dispatch(gotoPlan());
          }
        }                
        dispatch(setCurrentPlanInfo(action.payload.response.data.plan));
        dispatch(setHourlyRenderLoading(false));
        dispatch(getValidDates())
        if(getState().funnel.currentStep === 'DETAILS') {
          dispatch(setDetailsLoading(false));
        }
        if(getState().funnel.currentStep === 'BOOK' && !has(action.payload.response.data.plan, 'mover')) {
          dispatch(gotoCompare());
        }
        if(getState().funnel.currentStep === "PLAN") {
          dispatch(initPlanFinished());
        }
        if(has(getState().movePlan.currentPlan, 'mover') && getState().funnel.currentStep === 'INVENTORY') {
          dispatch(setInventoryLoading(false));
        }
        if(has(action.meta.originalRequest, 'redirect')) {
          if(action.meta.originalRequest.redirect === 'last_step') {
            if(getState().router.location.pathname === "/myhome") {
              dispatch(importFunnelState(action.payload.response.data.plan.last_step));
              if(action.payload.response.data.plan.last_step === 'congrats') {
                dispatch(push(`/moving/${getState().movePlan.currentMPID}/plan`));
              } else {
                dispatch(push(`/moving/${getState().movePlan.currentMPID}/${action.payload.response.data.plan.last_step}`));
              }
            } else if(action.payload.response.data.plan.is_booked) {
              window.location.href = `${process.env.REACT_APP_MARKETING_URL}/moving/${action.payload.response.data.plan.uuid}/plan`
            } else {
              dispatch(push(`/moving/${getState().movePlan.currentMPID}/${action.payload.response.data.plan.last_step}`));
            }
          }
          if(action.meta.originalRequest.redirect === 'old_site') {
            window.location.href = `${process.env.REACT_APP_MARKETING_URL}/moving/${getState().movePlan.currentMPID}/${action.payload.response.data.plan.last_step}`;
          }
          if(action.meta.originalRequest.redirect === 'inventory') {
            dispatch(push(`/moving/${getState().movePlan.currentMPID}/inventory`));
          }
          if(action.meta.originalRequest.redirect === 'inventory-continue') {
            dispatch(push(`/moving/${getState().movePlan.currentMPID}/inventory`));
          }
        }
        
        dispatch(setMoveplanLoading(false));
        const last_step = action.payload.response.data.plan.last_step.toUpperCase();
        if(last_step !== getState().funnel.lastStep) {
          dispatch(setLastStep(action.payload.response.data.plan.last_step.toUpperCase()));
        }
        if(getState().funnel.currentStep === 'COMPARE') {
          dispatch(setCompareLoading(true));
          dispatch(getBids());          
          if(
            action.payload.response.data.plan.source === 'Concierge'
            && !action.payload.response.data.plan.contact_first_name
            && !action.payload.response.data.plan.contact_last_name
            && !action.payload.response.data.plan.contact_email_address
            && !action.payload.response.data.plan.contact_phone_number
            ) {
              // document.getElementById('root').style.filter = 'blur(5px)'          
              dispatch(openModal({  name:"leads", properties: { position: 'top' }}));
            }
        }
        if(!isValidMovedate(getState().movePlan, getState().auth.isAdmin) && !action.payload.response.data.plan.is_booked) {
          dispatch(openModal({ name: 'moveDate' }))
        }
        if(getState().funnel.currentStep === 'INVENTORY'
          && action.payload.response.data.plan.details.home_size_id <= 7 
          && !action.payload.response.data.plan.is_booked
          && sessionStorage.getItem('addedTypicals') !== 'true'
          ) {         
          sessionStorage.setItem('addedTypicals', true);
          dispatch(openModal({  name:"typicals", properties: { position: 'top' }}));
        }
        break;
      case GET_VALID_DATES:
        dispatch(setValidDates(action.payload.response.data));
        break;
      case UPDATE_PACKING_SERVICES:
        if((action.meta.originalRequest.params.follow_up_packing_service_id === "1"
          || action.meta.originalRequest.params.follow_up_packing_service_id === "2")
          && hasBoxPurchases(getState().movePlan)){
          dispatch(updateBoxPurchases(null,null));
        } else {
          dispatch(getCurrentMoveplan());
        }
        break;
      case CHECK_VALID_DATES:
        dispatch(setValidDates(action.payload.response.data));
        break;
      case CHECK_MOVER_AVAILABILITY:
        const date = Object.keys(action.payload.response.data)[0];
        const props = {
          ...action.meta.originalRequest.params,
          price: action.payload.response.data[date]
        }
        dispatch(openModal({ name: 'requestReschedule', properties: props}));
        dispatch(setDetailsLoading(false));
        break;
      case EMAIL_PLAN:
        dispatch(setEmailError(false));
        dispatch(setEmailSent(true));
        break;
      case ADDRESS_UPDATE:        
        dispatch(setCurrentPlanInfo(action.payload.response.data.plan));
        break;
      case REQUEST_INSURANCE:
        dispatch(setMoveplanInsurance(true));
        dispatch(setPlanUpdating(false));
        break;
      case CANCEL_MOVE:
        dispatch(getCurrentMoveplan());
        dispatch(push(`/moving/${getState().movePlan.currentMPID}/compare`));
        dispatch(closeAllModals());
        break;
      case UPDATE_BOX_PURCHASES:
        if(action.meta.originalRequest.params.box_delivery_date === null) {
          dispatch(clearBoxPurchases())
        }
        dispatch(getCurrentMoveplan());
        dispatch(closeModal());
        break;
      case UNSELECT_MOVER:
        dispatch(setMoveplanLoading(false));
        break;
      default:
        break;
    }
  }

  if (action.type === NETWORK_FAILURE) {
    switch (action.meta.originalRequest.fromAction) {
      case CREATE_MOVEPLAN:
        dispatch(track('DNA create plan failed'))
        dispatch(openModal({ name: 'apiError', properties: { error: 'Something went wrong creating your move plan.'}}));
        dispatch(setMoveplanLoading(false));
        break;
      case EMAIL_PLAN:
        dispatch(setEmailError(true));
        dispatch(setEmailSent(false));
        dispatch(openModal({ name: 'apiError', properties: { error: 'Email was not sent.'}}));
        break;
      case REQUEST_INSURANCE:
        dispatch(setPlanUpdating(false));
        dispatch(openModal({ name: 'apiError', properties: { error: 'There was a problem adding insurance to this move plan.'}}))
        break;
      case GET_CURRENT_MOVEPLAN:
        // Account for the case where cookies are deleted but local storage remains
        if(has(action.meta.originalRequest, 'redirect')) {
          if(action.meta.originalRequest.redirect === 'inventory-continue') {
            dispatch(closeAllModals());
            dispatch(openModal({ name: 'authModal', properties: { authModalView: 'login', redirect: 'inventory' } }));
          }
        }
        break;
      case CHECK_MOVER_AVAILABILITY:
        dispatch(setDetailsLoading(false));
        break;
      case UPDATE_MOVE_PLAN:
        if(action.payload.response?.response 
          && action.payload.response.response.status === 422
          && action.payload.response?.response?.data?.exception) {           
          let exceptions = JSON.parse(action.payload.response.response.data.exception);
          dispatch(openModal({ 
            name: 'moverCannotSupport', 
            properties: { 
              errors: exceptions,  //array of strings
              params: action.meta.originalRequest.params
            }
          }))          
        } else if (action.payload.response?.response && action.payload.response.response.status === 422 && action.payload.response?.response?.data?.errors) {
          let errors = action.payload.response.response.data.errors.mover;
          dispatch(openModal({ 
            name: 'moverCannotSupport', 
            properties: { 
              errors: errors,  //array of strings
              params: action.meta.originalRequest.params
            }
          }))
        }
        dispatch(setMoveplanLoading(false));
        break;
      default:
        break;
    }
  }
};

export default movePlanMiddleware;
