import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MobileNav from './MobileNav';
import MobileDimmer from './MobileDimmer';
import { toggleLeftMenu } from '../../../redux/actions/ui/mobile';
import { connect } from 'react-redux';

const mapStateToProps = ({ ui, funnel }) => ({
  leftMenuVisible: ui.mobile.leftMenuVisible,
  currentStep: funnel.currentStep
});

const MobileHeader = props => {
  const [showNav, setShowNav] = useState(false);
  const [showDimmer, setShowDimmer] = useState(false);

  useEffect(() => setShowDimmer(props.leftMenuVisible), [props.leftMenuVisible]);
  useEffect(() => setShowDimmer(showNav), [showNav]);

  const handleToggle = () => setShowNav(!showNav);
  // const handleLeftSidebarToggle = () => props.toggleLeftMenu();
  const handleDimmerClick = () => {
    if (props.leftMenuVisible) props.toggleLeftMenu();
    else setShowNav(false);
    setShowDimmer(false);
  };
  return (
    <header id="header-container" className="header header-container ">
      <Link to="/" className="logo-container mobile d-inline-flex float-left " rel="canonical">
        <div className="logo-with-text">
          <img
            alt="Aqua Moving Logo"
            className="logo"
            width="70px"
            height="70px"
            src={`${process.env.REACT_APP_CDN_IMAGES}/Aqua-Moving_Logo_Dark.svg`}
          />
          {/* <span>The Moving App</span> */}
        </div>
      </Link>
      <MobileNav showNav={showNav} toggle={handleToggle} />
      {showDimmer && (
        <>
          <MobileDimmer handleToggle={handleDimmerClick} />
        </>
      )}
      {/* { (props.currentStep === 'INVENTORY' || props.currentStep === 'COMPARE') && 
        <div className={`plus-sign`}  onClick={handleLeftSidebarToggle}>
          <div className={`${props.leftMenuVisible ? 'rotated' : ''} plus-sign-interior`}>+</div>
        </div>
      } */}
      <div className={`hamburger ${showNav && 'menu-open'}`} onClick={handleToggle}>
        <span className="bar" />
      </div>
      <style jsx>{`
        .header {
          width: 100%;
          position: fixed;
          left: 0;
          top: 0;
          height: 70px;
          min-height: 70px;
          line-height: 70px;
          z-index: 1000;
          background: #76FFFE 0% 0% no-repeat padding-box;
          line-height: 70px;
        }
        .logo {
          fill: white;
          height: 70px;
          margin-right: 10px;
        }
        :global(.logo-container.mobile) {
          display: block;
          height: 100%;
          margin-right: 20px;
          padding-left: 15px;
        }
        .hamburger {
          position: absolute;
          display: block;
          right: 0;
          top: 1px;
          width: 65px;
          padding: 0 15px;
          height: 70px;
          transition: -webkit-transform 0.2s;
          transition: transform 0.2s;
          transition: transform 0.2s, -webkit-transform 0.2s;
          cursor: pointer;
        }
        .bar {
          top: 31px;
          position: absolute;
          top: 44%;
          width: 35px;
          height: 4px;
          background: var(--primary-color);
          transition: background 0s 0.2s;
        }
        .bar::before {
          top: -9px;
          transition: top 0.2s 0.2s, -webkit-transform 0.2s 0s;
          transition: top 0.2s 0.2s, transform 0.2s 0s;
          transition: top 0.2s 0.2s, transform 0.2s 0s, -webkit-transform 0.2s 0s;
          position: absolute;
          display: block;
          left: 0;
          width: 100%;
          height: 4px;
          background: var(--primary-color);
          content: '';
        }
        .bar:after {
          bottom: -9px;
          transition: bottom 0.2s 0.2s, -webkit-transform 0.2s 0s;
          transition: bottom 0.2s 0.2s, transform 0.2s 0s;
          transition: bottom 0.2s 0.2s, transform 0.2s 0s, -webkit-transform 0.2s 0s;
          position: absolute;
          display: block;
          left: 0;
          width: 100%;
          height: 4px;
background: var(--primary-color);
          content: '';
        }
        .menu-open {
          background: 0 0;
          transition: background 0s 0.2s;
          -webkit-transform: scale(0.7);
          -ms-transform: scale(0.7);
          transform: scale(0.7);
        }
        .menu-open span::before {
          top: 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          transition: top 0.2s 0s, -webkit-transform 0.2s 0.2s;
          transition: top 0.2s 0s, transform 0.2s 0.2s;
          transition: top 0.2s 0s, transform 0.2s 0.2s, -webkit-transform 0.2s 0.2s;
        }
        .menu-open span:after {
          bottom: 0;
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
          transition: bottom 0.2s 0s, -webkit-transform 0.2s 0.2s;
          transition: bottom 0.2s 0s, transform 0.2s 0.2s;
          transition: bottom 0.2s 0s, transform 0.2s 0.2s, -webkit-transform 0.2s 0.2s;
        }
        .menu-open span {
          background: 0 0;
          transition: background 0s 0.2s;
        }
        .logo-with-text {
          display: flex;
          align-items: flex-end;
          font-size: 0.75rem;
          color: #ffffffe8;
          justify-content: flex-start;
        }
        .logo-with-text span {
          margin: 0;
          line-height: 5;
          margin-left: -1.25rem;
        }
      `}</style>
    </header>
  );
};

export default connect(mapStateToProps, { toggleLeftMenu })(MobileHeader);
