import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { openModal } from '../../../redux/actions/modals';
import has from 'lodash-es/has';
import { signOut } from '../../../redux/actions/auth';

const mapStateToProps = ({ auth, ui }) => ({
  isMobile: ui.mobile.showMobileNav,
  user: !!auth.user ? auth.user : false,
  isAdmin: auth.isAdmin,
  isLoggedIn: has(auth, 'user')
});

const MobileNav = props => {
  const [showServices, setShowServices] = useState(false);
  const handleServicesToggle = () => setShowServices(!showServices);
  const handleAuthClick = context => {
    if (context === 'signout') {
      props.signOut();
    }
    const isNewDesign = sessionStorage.getItem('newDesign');
    if (isNewDesign) {
      if (context === 'login') window.location.href = `${process.env.REACT_APP_NEW_DESIGN_URL}/login`;
      if (context === 'register') window.location.href = `${process.env.REACT_APP_NEW_DESIGN_URL}/create-account`;
    } else {
      props.openModal({ name: 'authModal', properties: { authModalView: context, position: 'top' } });
      props.toggle();
    }
  };
  return (
    <nav className={`mobile-navbar ${props.showNav ? 'mobile-navbar-open' : 'mobile-navbar-close'}`}>
      {showServices && (
        <button style={{ position: 'absolute' }} aria-label="Go back" onClick={handleServicesToggle}></button>
      )}
      <div className="menu__wrap">
        {!showServices ? (
          <div className="menu__level">
            {!!props.user && (
              <div className="mobile-users-section">
                <div className="mobile-user-name-holder">
                  <span className="mobile-user-name">{props.user.name ? props.user.name : props.user.email}</span>
                </div>
                <ul className="mobile-menu mobile-user-menu">
                  <li className="mobile-menu-item user-item" ng-click="menuItemClick()">
                    <a href={`${process.env.REACT_APP_SUPPORT_URL}/my-home`} className="mobile-menu-item-link">
                      <span className="mobile-menu-item-link">My moves</span>
                    </a>
                  </li>
                  {props.isAdmin && (
                    <li className="mobile-menu-item user-item">
                      <a href={process.env.REACT_APP_BASE_API_URL + '/admin'} className="mobile-menu-item-link">
                        Admin Dashboard
                      </a>
                    </li>
                  )}
                  <li className="mobile-menu-item user-item">
                    <a
                      href={`${process.env.REACT_APP_DOMAIN}/users/edit`}
                      className="mobile-menu-item-link"
                      target="_self">
                      Account Settings
                    </a>
                  </li>
                  <li className="mobile-menu-item user-item">
                    <a
                      href={`${process.env.REACT_APP_DOMAIN}/benefits/`}
                      className="dropdown-menu-item-link mobile-menu-benefits-link">
                      Get $20
                    </a>
                  </li>
                </ul>
              </div>
            )}
            <ul className="mobile-menu mobile-main-menu">
              {/* <li className="mobile-menu-item active">
                <Link to="/">
                  <span className="mobile-menu-item-link active" onClick={props.toggle}>
                    Find a moving company
                  </span>
                </Link>
              </li> */}
              <li className="mobile-menu-item">
                <a href={`${process.env.REACT_APP_DOMAIN}/what-is-aqua/`}>
                  <span className="mobile-menu-item-link" onClick={props.toggle}>
                    About Us
                  </span>
                </a>
              </li>
              <li className="mobile-menu-item">
                <a href={`${process.env.REACT_APP_DOMAIN}/how-it-works/`}>
                  <span className="mobile-menu-item-link" onClick={props.toggle}>
                    HOW IT WORKS
                  </span>
                </a>
              </li>
              <li className="mobile-menu-item">
                <a href={`${process.env.REACT_APP_DOMAIN}/faq/`}>
                  <span className="mobile-menu-item-link" onClick={props.toggle}>
                    {' '}
                    FAQ
                  </span>
                </a>
              </li>
              <li className="mobile-menu-item">
                <Link to="/customer-service/" className="menu-item-link" onClick={props.toggle}>
                  Contact
                </Link>
              </li>
              {/* <li className="mobile-menu-item">
                <span className="mobile-services-item-link" onClick={handleServicesToggle}>
                  Services
                </span>
              </li>
              <li className="mobile-menu-item">
                <a href={`${process.env.REACT_APP_DOMAIN}/customer-service/`}>
                  <span className="mobile-menu-item-link">Customer Support</span>
                </a>
              </li>
              <li className="mobile-menu-item">
                <a href="https://blog.aqua.com/">
                  <span className="mobile-menu-item-link">Blog</span>
                </a>
              </li> */}
              {/* <li className="mobile-menu-item">
                <a href={`${process.env.REACT_APP_DOMAIN}/service-providers/`}>
                  <span className="mobile-menu-item-link"> For service providers</span>
                </a>
              </li> */}
              {/* <li className="mobile-menu-item" ng-if="isLoggedIn()">
                  <a className="mobile-menu-item-link" ng-click="menuItemClick(); signOut()">Log out</a>
                </li> */}
            </ul>

            <ul className="mobile-menu mobile-actions-menu" ng-if="!isLoggedIn()">
              {!props.isLoggedIn ? (
                <>
                  {/* <li className="mobile-action-menu-item">
                    <button className="mobile-cta-button primary-btn-small" onClick={() => handleAuthClick('register')}>
                      Sign Up
                    </button>
                  </li> */}
                  <li className="mobile-action-menu-item">
                    <button className="mobile-cta-button primary-btn-small" onClick={() => handleAuthClick('login')}>
                      Login
                    </button>
                  </li>
                </>
              ) : (
                <li className="mobile-action-menu-item w-100">
                  <button
                    className="mobile-cta-button primary-btn-small w-100 m-auto"
                    onClick={() => handleAuthClick('signout')}>
                    Logout
                  </button>
                </li>
              )}
            </ul>
          </div>
        ) : (
          <div className="menu__level">
            <div className="mobile-menu mobile-submenu">
              <div className="breadcrumb-container">
                <button className="breadcrumb-back" onClick={handleServicesToggle}></button>
                <div className="breadcrumb-current">Services</div>
              </div>
              <div className="mobile-submenu-list-container first">
                <span className="mobile-submenu-list-title">Moving</span>
                <ul className="mobile-submenu-list">
                  <li className="mobile-submenu-item">
                    <a href={`${process.env.REACT_APP_DOMAIN}/moving-services`} className="mobile-submenu-item-link">
                      Moving Services
                    </a>
                  </li>
                  <li className="mobile-submenu-item">
                    <a href={`${process.env.REACT_APP_DOMAIN}/moving-local`} className="mobile-submenu-item-link">
                      Local Moving
                    </a>
                  </li>
                  <li className="mobile-submenu-item">
                    <a href={`${process.env.REACT_APP_DOMAIN}/moving-long`} className="mobile-submenu-item-link">
                      Interstate Moving
                    </a>
                  </li>
                  <li className="mobile-submenu-item">
                    <a
                      href={`${process.env.REACT_APP_DOMAIN}/moving-international`}
                      className="mobile-submenu-item-link">
                      International Moving
                    </a>
                  </li>
                  <li className="mobile-submenu-item">
                    <a
                      href={`${process.env.REACT_APP_DOMAIN}/moving-truck-rental`}
                      className="mobile-submenu-item-link">
                      Moving Truck Rental
                    </a>
                  </li>

                  <li className="mobile-submenu-item">
                    <a className="mobile-submenu-item-link" href={`${process.env.REACT_APP_DOMAIN}/furniture-moving`}>
                      Moving Furniture
                    </a>
                  </li>
                  <li className="mobile-submenu-item">
                    <a
                      className="mobile-submenu-item-link"
                      href={`${process.env.REACT_APP_DOMAIN}/professional-movers`}>
                      Professional Movers
                    </a>
                  </li>
                  <li className="mobile-submenu-item">
                    <a className="mobile-submenu-item-link" href={`${process.env.REACT_APP_DOMAIN}/house-movers`}>
                      House Movers
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mobile-submenu-list-container">
                <span className="mobile-submenu-list-title">Storage</span>
                <ul className="mobile-submenu-list">
                  <li className="mobile-submenu-item">
                    <a href={`${process.env.REACT_APP_DOMAIN}/storage-services`} className="mobile-submenu-item-link">
                      Storage Services
                    </a>
                  </li>
                  <li className="mobile-submenu-item">
                    <a href={`${process.env.REACT_APP_DOMAIN}/storage-self`} className="mobile-submenu-item-link">
                      Self Storage
                    </a>
                  </li>
                  <li className="mobile-submenu-item ">
                    <a href={`${process.env.REACT_APP_DOMAIN}/storage-warehouse`} className="mobile-submenu-item-link">
                      Warehouse
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mobile-submenu-list-container">
                <span className="mobile-submenu-list-title">Insurance</span>
                <ul className="mobile-submenu-list">
                  <li className="mobile-submenu-item">
                    <a href={`${process.env.REACT_APP_DOMAIN}/insurance`} className="mobile-submenu-item-link">
                      Moving Insurance
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mobile-submenu-list-container text-center">
                {/* TODO: add homepage to this link when more of the funnel is completed */}
                <button onClick={props.toggle} className="mobile-submenu-cta-link">
                  <Link className="text-white" to="/">
                    Find A Moving Company
                  </Link>
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="spacer"></div>
      </div>
      <style jsx>{`

.primary-btn-small { 
//  background: #E33337;
//  color: white !important;
  color: var(--background-color) !important;
  background: var(--primary-color);
}
      
        .mobile-navbar {
          position: fixed;
          top: 70px;
          height: calc(100% - 70px);
          width: 75%;
          max-width: 480px;
          color: white;
          overflow: auto;
          transition: -webkit-transform .3s ease-in-out;
          transition: transform .3s ease-in-out;
          transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
          z-index: 1002;
        //  background: #000000;
          text-transform: uppercase;
          background: var(--background-color);
        }
        .mobile-menu-item-link {
          text-transform: uppercase;
          color:var(--primary-color);
        }
        .mobile-menu-item-link:hover {
          text-decoration: none;
        }
        .mobile-menu-item-link.active {
          color: #00bee8;
        }
        .mobile-navbar a:hover {
          cursor: pointer;
        }
        .mobile-navbar-close {
          -webkit-transform: translateX(-100%);
          -ms-transform: translateX(-100%);
          transform: translateX(-100%);
          transition: -webkit-transform .3s ease-in-out;
          transition: transform .3s ease-in-out;
          transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
        }
        .mobile-navbar-open {
          -webkit-transform: translateX(0);
          -ms-transform: translateX(0);
          transform: translateX(0);
        }
        .menu__level {
         // background: #000000;
         background: var(--background-color);
        }
        .mobile-main-menu {
          margin: 0;

          border-bottom: 1px solid rgba(255,255,255,.06);
          line-height: 55px;
          list-style-type: none;
          border-top: none;
          font-size: 14px;
          user-select: none;
        }
        .mobile-menu-item:first-child {
          border-top: none;
        }
        .mobile-menu {
          margin: 0;
          padding: 0;
        }
        .mobile-menu-item {
          position: relative;
          height: 55px;
          padding-left: 40px;
          border-top: 1px solid rgba(255,255,255,.06);
          border-bottom: 1px solid rgba(0,0,0,.17);
        }
        .mobile-menu-item::before, .mobile-submenu-item::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          background-color: #00bee8;
          content: '';
          -webkit-transition: width .3s ease;
          -o-transition: width .3s ease;
          transition: width .3s ease;
          color: inherit;
        }
        .mobile-menu-item-link {
          opacity: .7;
        }
        .mobile-services-item-link {
          opacity: .7;
        }
        .mobile-menu-item.active {
          color: #00bee8;
        }
        .mobile-menu-item.active::before {
          width: 5px;
        }
        .mobile-actions-menu {
          opacity: .7;
          padding: 20px;
          border-bottom: none;
          list-style: none;
          display: flex;
          justify-content: space-between;
        }
        .mobile-cta-button {
          display: block;
          max-height: 50px;
          line-height: 34px;
          transition: all .25s;

          width: 30vw;
          max-width: 200px;
          padding: 0;
        }
        .mobile-user-name-holder {
          position: relative;
          height: 55px;
          padding: 0 40px 0 20px;
          color: #00bee8;
          border-bottom: 1px solid rgba(0, 0, 0, 0.17);
          text-transform: uppercase;
          overflow: hidden;
          opacity: 0.7;
          -webkit-transform: translateX(0);
          -ms-transform: translateX(0);
          transform: translateX(0);
        }
        .mobile-user-name-holder::before {
          position: absolute;
          top: 3px;
          left: 12px;
          width: 15px;
          height: 100%;
          content: '';
          background: url('${process.env.REACT_APP_CDN_IMAGES}/icon_user.svg') no-repeat center center;
          background-size: contain;
          opacity: 
        }
        .mobile-user-name {
          display: inline-block;
          height: 100%;
          padding-left: 20px;
          line-height: 65px;
          vertical-align: top;
        }
        .user-item a {
          position: absolute;
          display: block;
          height: inherit;
          top: -7px;
        }
        .mobile-users-section::after {
          content: '';
          display: block;
          height: 1px;
          margin: 20px 0 20px;
          background: rgba(255, 255, 255, 0.06);
          background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.06)), color-stop(50%, #00bee8), color-stop(51%, #00bee8), color-stop(100%, rgba(255, 255, 255, 0.06)));
          background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.06) 0%, #00bee8 50%, #00bee8 51%, rgba(255, 255, 255, 0.06) 100%);
          background: -o-linear-gradient(left, rgba(255, 255, 255, 0.06) 0%, #00bee8 50%, #00bee8 51%, rgba(255, 255, 255, 0.06) 100%);
          background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.06)), color-stop(50%, #00bee8), color-stop(51%, #00bee8), to(rgba(255, 255, 255, 0.06)));
          background: linear-gradient(to right, rgba(255, 255, 255, 0.06) 0%, #00bee8 50%, #00bee8 51%, rgba(255, 255, 255, 0.06) 100%);
        }

        .mobile-submenu {
          margin: 0;
          text-transform: uppercase;
          border-bottom: 1px solid rgba(255, 255, 255, 0.06);
          line-height: 55px;
          
        }
        .breadcrumb-container {
          height: 55px;
          padding: 0 20px;
          text-transform: none;
          position: sticky;
          background: #042b3b;
          width: 100%;
          z-index: 1;
          top: 0;
          left: 0;
        }
        .breadcrumb-container * {
          display: inline-block;
          line-height: 55px;
          vertical-align: middle;

        }
        .breadcrumb-back {
          background: url('${process.env.REACT_APP_CDN_IMAGES}/left-arrow-chevron.svg') no-repeat center left/contain;
          padding-right: 40px;
          height: 20px;
          border: none;
        }
        .breadcrumb-current {
          font-size: 24px;
          padding-top: 1px;
        }
        .header .mobile-submenu-list-title {
          display: block;
          background: #042b3b;
          height: 40px;
          line-height: 40px;
          padding-left: 20px;
        }
        .mobile-submenu-list-title {
          display: block;
          background: #042b3b;
          height: 40px;
          line-height: 40px;
          padding-left: 20px;
          background: #042b3b;
        }
        .mobile-submenu-item {
          position: relative;
          height: 55px;
          padding-left: 40px;
          border-top: 1px solid rgba(255, 255, 255, 0.06);
          border-bottom: 1px solid rgba(0, 0, 0, 0.17);
          text-transform: none;
        }
        .mobile-submenu-item-link {
          display: block;
          height: 100%;
          color: inherit;
          opacity: .7;
        }
        .mobile-submenu-list {
          margin: 0;
        }
        .mobile-submenu-cta-link {
          height: 42px;
          text-transform: none;
          display: inline-block;
          width: 80%;
          margin: 20px auto;
          border: 1px solid #bc5300;
          line-height: 2.5;
          background: #00bee8;
          color: white;
          border-radius: 3px;
          text-shadow: 0px 1px 1px #bc5300;
        }
      `}</style>
    </nav>
  );
};

export default connect(mapStateToProps, { openModal, signOut })(MobileNav);
