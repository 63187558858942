import React from 'react';
import ReactLoading from 'react-loading';

const CtaButton = ({ buttonText, loading }) => (
  <div className="button-container">
    <button className="primary-btn-large w-100" type="submit">
      {loading ? (
        <ReactLoading className="m-auto" type={'spokes'} color={'#ffffff'} height={30} width={30} />
      ) : (
        <span>{buttonText}</span>
      )}
    </button>
    <style jsx>{`
      .primary-btn-large {
        min-height: 40px;
      }
      .cta-button {
        line-height: 1;
        text-align: center;
        padding: 12px 10px;
        background-color: #00bee8;
        color: white;
        border: 1px solid #bc5300;
        border-radius: 2px;
        text-shadow: 0 1px 1px #e67200;
        cursor: pointer;
        font-size: 1.125rem;
        font-family: ProximaNovaRegular, sans-serif;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizelegibility;
      }
      .cta-button-tablet {
        height: inherit;
        vertical-align: middle;
        background-color: #00bee8;
        color: white;
        border: 1px solid #bc5300;
        border-radius: 2px;
        text-shadow: 0 1px 1px #e67200;
        cursor: pointer;
        font-size: 0.875rem;
        text-transform: uppercase;
        font-family: ProximaNovaSemiBold, sans-serif;
        text-rendering: optimizelegibility;
      }
      .cta-button-desktop {
        padding: 12px 10px;
        background-color: #00bee8;
        color: white;
        border: 1px solid #bc5300;
        border-radius: 2px;
        text-shadow: 0 1px 1px #e67200;
        cursor: pointer;
        font-size: 1.125rem;
        font-weight: 400;
        font-style: normal;
      }
      .cta-button-wide {
        padding: 12px 10px;
        background-color: #00bee8;
        color: white;
        border: 1px solid #bc5300;
        border-radius: 2px;
        text-shadow: 0 1px 1px #e67200;
        cursor: pointer;
        font-size: 1.125rem;
        font-weight: 400;
        font-style: normal;
      }
    `}</style>
  </div>
);

export default CtaButton;
