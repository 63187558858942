import React from 'react';

export const FLOW_STEPS = ['INVENTORY', 'DETAILS', 'COMPARE', 'PLAN', 'BOOK', 'CONGRATS'];

export const FUNNEL_STEPS = {
  INVENTORY: {
    id: 'inventory',
    title: 'Inventory',
    step: 'INVENTORY',
    nextStepButtonText: 'continue',
    showNextStepButton: true,
    isCompleted: false,
    nextStep: 'DETAILS',
    type: 'button'
  },
  DETAILS: {
    id: 'details',
    title: 'Details',
    step: 'INVENTORY',
    nextStepButtonText: 'continue',
    showNextStepButton: false,
    isCompleted: false,
    nextStep: 'COMPARE',
    type: 'submit'
  },
  COMPARE: {
    id: 'compare',
    title: 'Compare Movers',
    step: 'INVENTORY',
    nextStepButtonText: 'continue',
    showNextStepButton: false,
    isCompleted: false,
    nextStep: 'BOOK',
    type: 'button'
  },
  BOOK: {
    id: 'book',
    title: 'Book Online',
    step: 'INVENTORY',
    nextStepButtonText: 'Book',
    showNextStepButton: true,
    isCompleted: false,
    nextStep: 'CONGRATS',
    type: 'submit'
  },
  PLAN: {
    id: 'plan',
    title: 'Review Your Move Plan',
    step: 'PLAN',
    nextStepButtonText: '',
    showNextStepButton: false,
    isCompleted: false,
    nextStep: '',
    type: ''
  }
};

export const HOURLY_STEPS = {
  COMPARE: {
    id: 'compare',
    title: 'Compare Movers',
    step: 'INVENTORY',
    nextStepButtonText: 'continue',
    showNextStepButton: false,
    isCompleted: false,
    nextStep: 'DETAILS',
    type: 'button'
  },
  DETAILS: {
    id: 'details',
    title: 'Details',
    step: 'INVENTORY',
    nextStepButtonText: 'continue',
    showNextStepButton: false,
    isCompleted: false,
    nextStep: 'BOOK',
    type: 'submit'
  },
  BOOK: {
    id: 'book',
    title: 'Book Online',
    step: 'INVENTORY',
    nextStepButtonText: 'Book',
    showNextStepButton: true,
    isCompleted: false,
    nextStep: 'CONGRATS',
    type: 'submit'
  },
  PLAN: {
    id: 'plan',
    title: 'Review Your Move Plan',
    step: 'PLAN',
    nextStepButtonText: '',
    showNextStepButton: false,
    isCompleted: false,
    nextStep: '',
    type: ''
  }
};

export const PROGRESS_BAR_STEPS = {
  MOBILE: [
    { title: 'My Inventory', state: 'INVENTORY', id: 'inventory' },
    { title: 'Details', state: 'DETAILS', id: 'details' },
    { title: 'Compare Movers', state: 'COMPARE', id: 'compare' },
    { title: 'Book Online', state: 'BOOK', id: 'book' }
  ],
  DESKTOP: [
    { title: 'My Inventory', state: 'INVENTORY', id: 'inventory' },
    { title: 'Details', state: 'DETAILS', id: 'details' },
    { title: 'Compare Movers', state: 'COMPARE', id: 'compare' },
    { title: 'Book Online', state: 'BOOK', id: 'book' }
  ],
  HOURLY_MOBILE: [
    { title: 'Compare Movers', state: 'COMPARE', id: 'compare' },
    { title: 'Details', state: 'DETAILS', id: 'details' },
    { title: 'Book Online', state: 'BOOK', id: 'book' }
  ],
  HOURLY_DESKTOP: [
    { title: 'Compare Movers', state: 'COMPARE', id: 'compare' },
    { title: 'Details', state: 'DETAILS', id: 'details' },
    { title: 'Book Online', state: 'BOOK', id: 'book' }
  ]
};

export const flatrateNetworkSupportNumber = '855-286-7258';

export const TWITTER_SHARE_TEXT =
  'Found the best way to move! @AquaNetwork - compare movers & prices! Use this link and save $20 on your move.';

// NOTE: THERE IS AN EMPTY ROOM AT POSITION 9
export const INVENTORY_ROOMS = [
  'Living Room',
  'Bedroom',
  'Dining Room',
  'Kitchen',
  'Kids Room',
  'Office',
  'Outdoor',
  'Other',
  'Boxes I Packed'
];

export const INVENTORY_GROUPS = [
  [
    {
      id: 27,
      name: 'Sofas and Couches',
      room_id: 1,
      icon_css_class: 'inventory_icons-Seater_Sofa_3'
    },
    {
      id: 28,
      name: 'Chairs',
      room_id: 1,
      icon_css_class: 'inventory_icons-Arm_Chair'
    },
    {
      id: 29,
      name: 'Tables',
      room_id: 1,
      icon_css_class: 'inventory_icons-Large_Coffee_Table'
    },
    {
      id: 63,
      name: 'Glass Tables',
      room_id: 1,
      icon_css_class: 'inventory_icons-Large_Coffee_Table'
    },
    {
      id: 30,
      name: 'TVs',
      room_id: 1,
      icon_css_class: 'inventory_icons-TV_FLAT_PANEL_46-55'
    },
    {
      id: 37,
      name: 'Stereo',
      room_id: 1,
      icon_css_class: 'inventory_icons-Stereo_or_Video_Component'
    },
    {
      id: 31,
      name: 'Cabinets',
      room_id: 1,
      icon_css_class: 'inventory_icons-Small_China_Cabinet'
    },
    {
      id: 32,
      name: 'Lamps',
      room_id: 1,
      icon_css_class: 'inventory_icons-Table_Lamp'
    },
    {
      id: 33,
      name: 'Bookcases',
      room_id: 1,
      icon_css_class: 'inventory_icons-Large_Bookcase'
    },
    {
      id: 34,
      name: 'Pianos',
      room_id: 1,
      icon_css_class: 'inventory_icons-Baby_Grand_Piano'
    },
    {
      id: 35,
      name: 'Mirrors',
      room_id: 1,
      icon_css_class: 'inventory_icons-Small_Mirror'
    },
    {
      id: 36,
      name: 'Paintings & Pictures',
      room_id: 1,
      icon_css_class: 'inventory_icons-Picture_with_Frame'
    },
    {
      id: 38,
      name: 'Odds & Ends',
      room_id: 1,
      icon_css_class: 'inventory_icons-Fireplace_Equipment'
    }
  ],
  [
    {
      id: 1,
      name: 'Beds',
      room_id: 2,
      icon_css_class: 'inventory_icons-Bed'
    },
    {
      id: 2,
      name: 'Mattress Only',
      room_id: 2,
      icon_css_class: 'inventory_icons-California_King_Mattress'
    },
    {
      id: 3,
      name: 'Cabinets',
      room_id: 2,
      icon_css_class: 'inventory_icons-Small_Armoire'
    },
    {
      id: 4,
      name: 'TVs',
      room_id: 2,
      icon_css_class: 'inventory_icons-TV_FLAT_PANEL_46-55'
    },
    {
      id: 10,
      name: 'Stereo',
      room_id: 2,
      icon_css_class: 'inventory_icons-Stereo_or_Video_Component'
    },
    {
      id: 5,
      name: 'Lamps',
      room_id: 2,
      icon_css_class: 'inventory_icons-Table_Lamp'
    },
    {
      id: 6,
      name: 'Tables',
      room_id: 2,
      icon_css_class: 'inventory_icons-Night_Table'
    },
    {
      id: 7,
      name: 'Chairs',
      room_id: 2,
      icon_css_class: 'inventory_icons-Arm_Chair'
    },
    {
      id: 8,
      name: 'Mirrors',
      room_id: 2,
      icon_css_class: 'inventory_icons-Small_Mirror'
    },
    {
      id: 9,
      name: 'Bookcases',
      room_id: 2,
      icon_css_class: 'inventory_icons-Large_Bookcase'
    },
    {
      id: 11,
      name: 'Paintings & Pictures',
      room_id: 2,
      icon_css_class: 'inventory_icons-Picture_with_Frame'
    },
    {
      id: 12,
      name: 'Odds & Ends',
      room_id: 2,
      icon_css_class: 'inventory_icons-Clothes_Steamer'
    }
  ],
  [
    {
      id: 13,
      name: 'Tables',
      room_id: 3,
      icon_css_class: 'inventory_icons-Dining_Table'
    },
    {
      id: 62,
      name: 'Glass Tables',
      room_id: 3,
      icon_css_class: 'inventory_icons-Dining_Table'
    },
    {
      id: 14,
      name: 'Chairs',
      room_id: 3,
      icon_css_class: 'inventory_icons-Dining_Chair'
    },
    {
      id: 15,
      name: 'Cabinets',
      room_id: 3,
      icon_css_class: 'inventory_icons-Small_China_Cabinet'
    },
    {
      id: 65,
      name: 'Paintings & Pictures',
      room_id: 3,
      icon_css_class: 'inventory_icons-Picture_with_Frame'
    },
    {
      id: 16,
      name: 'Odds & Ends',
      room_id: 3,
      icon_css_class: 'inventory_icons-Chandelier'
    }
  ],
  [
    {
      id: 22,
      name: 'Tables',
      room_id: 4,
      icon_css_class: 'inventory_icons-Kitchen_Table'
    },
    {
      id: 23,
      name: 'Chairs',
      room_id: 4,
      icon_css_class: 'inventory_icons-Kitchen_Chair'
    },
    {
      id: 24,
      name: 'Cabinets',
      room_id: 4,
      icon_css_class: 'inventory_icons-Small_China_Cabinet'
    },
    {
      id: 25,
      name: 'Appliances',
      room_id: 4,
      icon_css_class: 'inventory_icons-Coffee_maker'
    },
    {
      id: 26,
      name: 'Odds & Ends',
      room_id: 4,
      icon_css_class: 'inventory_icons-Water_Cooler'
    }
  ],
  [
    {
      id: 17,
      name: 'Beds',
      room_id: 5,
      icon_css_class: 'inventory_icons-Childrens_Bed'
    },
    {
      id: 18,
      name: 'Tables',
      room_id: 5,
      icon_css_class: 'inventory_icons-Childrens_Table'
    },
    {
      id: 19,
      name: 'Chairs',
      room_id: 5,
      icon_css_class: 'inventory_icons-Childrens_Chair'
    },
    {
      id: 20,
      name: 'Toys',
      room_id: 5,
      icon_css_class: 'inventory_icons-Toy_Car'
    },
    {
      id: 21,
      name: 'Odds & Ends',
      room_id: 5,
      icon_css_class: 'inventory_icons-Stroller'
    }
  ],
  [
    {
      id: 39,
      name: 'Tables & Desks',
      room_id: 6,
      icon_css_class: 'inventory_icons-Small_Desk'
    },
    {
      id: 40,
      name: 'Chairs',
      room_id: 6,
      icon_css_class: 'inventory_icons-Office-Chair'
    },
    {
      id: 41,
      name: 'Computers',
      room_id: 6,
      icon_css_class: 'inventory_icons-Computer'
    },
    {
      id: 42,
      name: 'Monitors',
      room_id: 6,
      icon_css_class: 'inventory_icons-Monitor'
    },
    {
      id: 43,
      name: 'Equipment',
      room_id: 6,
      icon_css_class: 'inventory_icons-Fax_Machine'
    },
    {
      id: 44,
      name: 'Cabinets',
      room_id: 6,
      icon_css_class: 'inventory_icons-Drawer_Lateral_4'
    },
    {
      id: 45,
      name: 'Bookcase',
      room_id: 6,
      icon_css_class: 'inventory_icons-Large_Bookcase'
    }
  ],
  [
    {
      id: 57,
      name: 'Tables',
      room_id: 7,
      icon_css_class: 'inventory_icons-Outdoor_Dining_Table'
    },
    {
      id: 58,
      name: 'Chairs',
      room_id: 7,
      icon_css_class: 'inventory_icons-Outdoor_Chair'
    },
    {
      id: 59,
      name: 'Sofas',
      room_id: 7,
      icon_css_class: 'inventory_icons-Outdoor_Sofa'
    },
    {
      id: 60,
      name: 'Plants',
      room_id: 7,
      icon_css_class: 'inventory_icons-Medium_Plant'
    },
    {
      id: 61,
      name: 'Grills',
      room_id: 7,
      icon_css_class: 'inventory_icons-Large_Grill'
    }
  ],
  [
    {
      id: 46,
      name: 'Instruments',
      room_id: 8,
      icon_css_class: 'inventory_icons-Guitar'
    },
    {
      id: 47,
      name: 'Chairs',
      room_id: 8,
      icon_css_class: 'inventory_icons-Dining_Chair'
    },
    {
      id: 64,
      name: 'Cabinets',
      room_id: 8,
      icon_css_class: 'inventory_icons-Small_China_Cabinet'
    },
    {
      id: 48,
      name: 'Sports & Hobbies',
      room_id: 8,
      icon_css_class: 'inventory_icons-Snowboard'
    },
    {
      id: 49,
      name: 'Home Appliances',
      room_id: 8,
      icon_css_class: 'inventory_icons-Vacuum_Cleaner'
    },
    {
      id: 50,
      name: 'Stereo Equipment',
      room_id: 8,
      icon_css_class: 'inventory_icons-Stereo_or_Video_Component'
    },
    {
      id: 51,
      name: 'Games',
      room_id: 8,
      icon_css_class: 'inventory_icons-Pool_Table'
    },
    {
      id: 52,
      name: 'Odds & Ends',
      room_id: 8,
      icon_css_class: 'inventory_icons-Basket'
    },
    {
      id: 53,
      name: 'Tables',
      room_id: 8,
      icon_css_class: 'inventory_icons-Dining_Table'
    },
    {
      id: 54,
      name: 'Rugs',
      room_id: 8,
      icon_css_class: 'inventory_icons-Large_Rug'
    },
    {
      id: 55,
      name: 'Art & Sculptures',
      room_id: 8,
      icon_css_class: 'inventory_icons-Sculpture'
    },
    {
      id: 56,
      name: 'Travel & Storage',
      room_id: 8,
      icon_css_class: 'inventory_icons-Trunk'
    }
  ],
  [
    {
      id: 66,
      name: 'Boxes',
      room_id: 10,
      icon_css_class: 'inventory_icons-Box'
    }
  ]
];
export const HOME_SIZES = {
  1: {
    type: 'Studio',
    size: '400-600 sq ft',
    short_formatted_type: 'A studio'
  },
  2: {
    type: 'Studio Alcove',
    size: '600-700 sq ft',
    short_formatted_type: 'A studio alcove'
  },
  3: {
    type: '1 Bedroom, Small',
    size: '600-800 sq ft',
    short_formatted_type: '1 small bedroom'
  },
  4: {
    type: '1 Bedroom, Large',
    size: '800-1000 sq ft',
    short_formatted_type: '1 large bedroom'
  },
  5: {
    type: '2 Bedroom',
    size: '1000 to 1500 sq ft',
    short_formatted_type: '2 bedrooms'
  },
  6: {
    type: '3 Bedroom',
    size: '1500 to 2000 sq ft',
    short_formatted_type: '3 bedrooms'
  },
  7: {
    type: '4+ Bedroom',
    size: 'over 2000 sq ft'
  },
  16: {
    type: 'Just a few items',
    size: '',
    short_formatted_type: 'Just a few items'
  },
  17: {
    type: 'One item',
    size: '',
    short_formatted_type: 'One item'
  }
};

export const INVENTORY_BOXES = [
  {
    id: 1,
    name: 'Small Box',
    description: 'For small and heavy items',
    size: '12"x12.5"x17"',
    cubic_feet: 1.5,
    icon: 'linen'
  },
  {
    id: 2,
    name: 'Medium Box',
    description: 'For general stuff',
    size: '16"x16"x18"',
    cubic_feet: 3.0,
    icon: 'medium'
  },
  {
    id: 3,
    name: 'Large Box',
    description: 'For large and light items',
    size: '20"x20"x18"',
    cubic_feet: 4.5,
    icon: 'large'
  },
  {
    id: 4,
    name: 'China/Dish Box',
    description: 'For fragile items',
    size: '18"x18"x28"',
    cubic_feet: 5.2,
    icon: 'china'
  },
  {
    id: 5,
    name: 'File Box',
    description: 'For folders and files',
    size: '12"x12"x24"',
    cubic_feet: 2.0,
    icon: 'letter'
  },
  {
    id: 6,
    name: 'Legal File Box',
    description: 'For folders',
    size: '12"x15"x24"',
    cubic_feet: 2.5,
    icon: 'legal'
  },
  {
    id: 7,
    name: 'Wardrobe Box',
    description: 'For hangers',
    size: '24"x23"x48"',
    cubic_feet: 15.5,
    icon: 'wardrobe'
  }
];

export const FLOW_STEPS_MAP = {
  inventory: {
    inventory: {
      text: 'continue',
      action: 'submitInventory',
      show: true,
      loader: false,
      listener: false,
      redirectTo: undefined,
      onLoad: 'startSaveTimeOut'
    }
  },
  details: {
    inventory: {
      text: 'continue',
      action: 'submitInventory',
      show: true,
      loader: false,
      listener: false,
      redirectTo: undefined,
      onLoad: 'startSaveTimeOut'
    },
    location: {
      text: 'continue',
      action: 'submitLocation',
      show: true,
      loader: false,
      listener: false,
      redirectTo: undefined,
      onLoad: 'startSaveTimeOut'
    }
  },
  compare: {
    inventory: {
      text: 'continue',
      action: 'submitInventory',
      show: true,
      loader: true,
      listener: false,
      redirectTo: undefined,
      onLoad: 'startSaveTimeOut'
    },
    location: {
      text: 'continue',
      action: 'submitLocation',
      show: true,
      loader: false,
      listener: false,
      redirectTo: undefined,
      onLoad: 'startSaveTimeOut'
    },
    compare: {
      text: '',
      action: 'checkPlanState',
      show: false,
      loader: false,
      listener: false,
      redirectTo: undefined,
      onLoad: 'startSaveTimeOut'
    }
  },
  confirm: {
    inventory: {
      text: 'continue',
      action: 'submitInventory',
      show: true,
      loader: false,
      listener: false,
      redirectTo: undefined
    },
    location: {
      text: 'save',
      action: 'submitLocation',
      show: true,
      loader: false,
      listener: false,
      redirectTo: undefined
    },
    compare: {
      text: '',
      action: 'checkPlanState',
      show: true,
      loader: false,
      listener: false,
      redirectTo: undefined
    }
  },
  book: {
    inventory: {
      text: 'continue',
      action: 'submitInventory',
      show: true,
      loader: false,
      listener: false,
      redirectTo: undefined
    },
    location: {
      text: 'save',
      action: 'submitLocation',
      show: true,
      loader: false,
      listener: false,
      redirectTo: undefined
    },
    compare: {
      text: '',
      action: 'checkPlanState',
      show: true,
      loader: false,
      listener: false,
      redirectTo: undefined
    }
  },
  congrats: {
    inventory: {
      text: 'continue',
      action: 'submitInventory',
      show: true,
      loader: false,
      listener: false,
      redirectTo: 'plan'
    },
    location: {
      text: 'save',
      action: 'submitLocation',
      show: true,
      loader: false,
      listener: false,
      redirectTo: 'plan'
    },
    compare: {
      text: '',
      action: 'checkPlanState',
      show: true,
      loader: false,
      listener: false,
      redirectTo: 'plan'
    }
  },
  billing: {
    inventory: {
      text: 'continue',
      action: 'submitInventory',
      show: true,
      loader: false,
      listener: false,
      redirectTo: undefined
    },
    location: {
      text: 'save',
      action: 'submitLocation',
      show: true,
      loader: false,
      listener: false,
      redirectTo: undefined
    },
    compare: {
      text: '',
      action: 'checkPlanState',
      show: true,
      loader: false,
      listener: false,
      redirectTo: undefined
    }
  }
};

export const TIME_OPTIONS = [
  '8:00 AM - 9:00 AM',
  '9:00 AM - 10:00 AM',
  '10:00 AM - 11:00 AM',
  '11:00 AM - 12:00 PM',
  '12:00 PM - 1:00 PM',
  '1:00 PM - 2:00 PM',
  '2:00 PM - 3:00 PM',
  '3:00 PM - 4:00 PM',
  '4:00 PM - 5:00 PM',
  '5:00 PM - 6:00 PM',
  '6:00 PM - 7:00 PM'
];

export const TIME_OPTIONS_SELECT = [
  { value: '8:00 AM - 9:00 AM', label: '8:00 AM - 9:00 AM', disabled: false },
  { value: '9:00 AM - 10:00 AM', label: '9:00 AM - 10:00 AM', disabled: false },
  { value: '10:00 AM - 11:00 AM', label: '10:00 AM - 11:00 AM', disabled: false },
  { value: '11:00 AM - 12:00 PM', label: '11:00 AM - 12:00 PM', disabled: false },
  { value: '12:00 PM - 1:00 PM', label: '12:00 PM - 1:00 PM', disabled: false },
  { value: '1:00 PM - 2:00 PM', label: '1:00 PM - 2:00 PM', disabled: false },
  { value: '2:00 PM - 3:00 PM', label: '2:00 PM - 3:00 PM', disabled: false },
  { value: '3:00 PM - 4:00 PM', label: '3:00 PM - 4:00 PM', disabled: false },
  { value: '4:00 PM - 5:00 PM', label: '4:00 PM - 5:00 PM', disabled: false },
  { value: '5:00 PM - 6:00 PM', label: '5:00 PM - 6:00 PM', disabled: false },
  { value: '6:00 PM - 7:00 PM', label: '6:00 PM - 7:00 PM', disabled: false }
];

export const ELEVATOR_OPTIONS_SELECT = [
  { value: '7:00 AM', label: '7:00 AM', disabled: false },
  { value: '8:00 AM', label: '8:00 AM', disabled: false },
  { value: '9:00 AM', label: '9:00 AM', disabled: false },
  { value: '10:00 AM', label: '10:00 AM', disabled: false },
  { value: '11:00 AM', label: '11:00 AM', disabled: false },
  { value: '12:00 PM', label: '12:00 PM', disabled: false },
  { value: '1:00 PM', label: '1:00 PM', disabled: false },
  { value: '2:00 PM', label: '2:00 PM', disabled: false },
  { value: '3:00 PM', label: '3:00 PM', disabled: false },
  { value: '4:00 PM', label: '4:00 PM', disabled: false },
  { value: '5:00 PM', label: '5:00 PM', disabled: false },
  { value: '6:00 PM', label: '6:00 PM', disabled: false },
  { value: '7:00 PM', label: '7:00 PM', disabled: false },
  { value: '8:00 PM', label: '8:00 PM', disabled: false },
  { value: '9:00 PM', label: '9:00 PM', disabled: false },
  { value: '10:00 PM', label: '10:00 PM', disabled: false }
];

export const TIME_FRAMES = [
  {
    time: '8-10 AM',
    full_time: '8:00 AM - 10:00 AM'
  },
  {
    time: '9-11 AM',
    full_time: '9:00 AM - 11:00 AM'
  },
  {
    time: '1-3 PM',
    full_time: '1:00 PM - 3:00 PM'
  },
  {
    time: '2-4 PM',
    full_time: '2:00 PM - 4:00 PM'
  }
];

export const NUMBER_OF_LABORERERS = [
  {
    text_num: '1',
    value: 1
  },
  {
    text_num: '2',
    value: 2
  },
  {
    text_num: '3',
    value: 3
  },
  {
    text_num: '4',
    value: 4
  },
  {
    text_num: '5',
    value: 5
  },
  {
    text_num: '6',
    value: 6
  }
];

export const TRUCK_SIZE_SELECT = [
  { value: 1, text: 'No Truck', subText: 'labor only', short_formatted_type: 'I just need labor' },
  { value: 2, text: 'Small Truck', subText: '(10-13 ft)', short_formatted_type: 'A studio' },
  { value: 3, text: 'Medium Truck', subText: '(14-19 ft)', short_formatted_type: '1 small bedroom' },
  { value: 4, text: 'Large Truck', subText: '(20-26 ft)', short_formatted_type: '2 bedrooms' },
  { value: 5, text: 'Extra Large Truck', subText: '(>27 ft)', short_formatted_type: '3+ bedrooms' }
];
export const HOME_SIZES_SELECT = [
  { value: 17, text: 'One item', subText: false, short_formatted_type: 'One item' },
  { value: 16, text: 'Just a few items', subText: false, short_formatted_type: 'Just a few items' },
  { value: 1, text: 'Studio', subText: '(400-600 sq ft)', short_formatted_type: 'A studio' },
  { value: 2, text: 'Studio Alcove', subText: '(600-700 sq ft)', short_formatted_type: 'A studio alcove' },
  { value: 3, text: '1 Bedroom, Small', subText: '(600-800 sq ft)', short_formatted_type: '1 small bedroom' },
  { value: 4, text: '1 Bedroom, Large', subText: '(800-1000 sq ft)', short_formatted_type: '1 large bedroom' },
  { value: 5, text: '2 Bedroom', subText: '(1000 to 1500 sq ft)', short_formatted_type: '2 bedrooms' },
  { value: 6, text: '3 Bedroom', subText: '(1500 to 2000 sq ft)', short_formatted_type: '3 bedrooms' },
  { value: 7, text: '4+ Bedroom', subText: 'over 2000 sq ft', short_formatted_type: '4+ bedrooms' }
];

export const STATES = [
  { value: 'AL', label: 'AL', full: 'Alabama' },
  { value: 'AK', label: 'AK', full: 'Alaska' },
  { value: 'AZ', label: 'AZ', full: 'Arizona' },
  { value: 'AR', label: 'AR', full: 'Arkansas' },
  { value: 'CA', label: 'CA', full: 'California' },
  { value: 'CO', label: 'CO', full: 'Colorado' },
  { value: 'CT', label: 'CT', full: 'Connecticut' },
  { value: 'DC', label: 'DC', full: 'District of Columbia' },
  { value: 'DE', label: 'DE', full: 'Delaware' },
  { value: 'FL', label: 'FL', full: 'Florida' },
  { value: 'GA', label: 'GA', full: 'Georgia' },
  { value: 'HI', label: 'HI', full: 'Hawaii' },
  { value: 'ID', label: 'ID', full: 'Idaho' },
  { value: 'IL', label: 'IL', full: 'Illinois' },
  { value: 'IN', label: 'IN', full: 'Indiana' },
  { value: 'IA', label: 'IA', full: 'Iowa' },
  { value: 'KS', label: 'KS', full: 'Kansas' },
  { value: 'KY', label: 'KY', full: 'Kentucky' },
  { value: 'LA', label: 'LA', full: 'Louisiana' },
  { value: 'ME', label: 'ME', full: 'Maine' },
  { value: 'MD', label: 'MD', full: 'Maryland' },
  { value: 'MA', label: 'MA', full: 'Massachusetts' },
  { value: 'MI', label: 'MI', full: 'Michigan' },
  { value: 'MN', label: 'MN', full: 'Minnesota' },
  { value: 'MS', label: 'MS', full: 'Mississippi' },
  { value: 'MO', label: 'MO', full: 'Missouri' },
  { value: 'MT', label: 'MT', full: 'Montana' },
  { value: 'NE', label: 'NE', full: 'Nebraska' },
  { value: 'NV', label: 'NV', full: 'Nevada' },
  { value: 'NH', label: 'NH', full: 'New Hampshire' },
  { value: 'NJ', label: 'NJ', full: 'New Jersey' },
  { value: 'NM', label: 'NM', full: 'New Mexico' },
  { value: 'NY', label: 'NY', full: 'New York' },
  { value: 'NC', label: 'NC', full: 'North Carolina' },
  { value: 'ND', label: 'ND', full: 'North Dakota' },
  { value: 'OH', label: 'OH', full: 'Ohio' },
  { value: 'OK', label: 'OK', full: 'Oklahoma' },
  { value: 'OR', label: 'OR', full: 'Oregon' },
  { value: 'PA', label: 'PA', full: 'Pennsylvania' },
  { value: 'RI', label: 'RI', full: 'Rhode Island' },
  { value: 'SC', label: 'SC', full: 'South Carolina' },
  { value: 'SD', label: 'SD', full: 'South Dakota' },
  { value: 'TN', label: 'TN', full: 'Tennessee' },
  { value: 'TX', label: 'TX', full: 'Texas' },
  { value: 'UT', label: 'UT', full: 'Utah' },
  { value: 'VT', label: 'VT', full: 'Vermont' },
  { value: 'VA', label: 'VA', full: 'Virginia' },
  { value: 'WA', label: 'WA', full: 'Washington' },
  { value: 'WV', label: 'WV', full: 'West Virginia' },
  { value: 'WI', label: 'WI', full: 'Wisconsin' },
  { value: 'WY', label: 'WY', full: 'Wyoming' }
];

export const HEIGHTS_BY_ID = {
  1: 'Elevator',
  2: 'No stairs - ground floor',
  3: 'Stairs - 2nd floor',
  4: 'Stairs - 3rd floor',
  5: 'Stairs - 4th floor',
  6: 'Stairs - 5th floor',
  7: 'Stairs - 6th floor'
};

export const HEIGHTS = [
  { value: '1', label: 'Elevator' },
  { value: '2', label: 'No stairs - ground floor' },
  { value: '3', label: 'Stairs - 2nd floor' },
  { value: '4', label: 'Stairs - 3rd floor' },
  { value: '5', label: 'Stairs - 4th floor' },
  { value: '6', label: 'Stairs - 5th floor' },
  { value: '7', label: 'Stairs - 6th floor' }
];

export const CHECKLIST_ITEMS = [
  {
    title: 'Reserve your elevator and ask if a COI is required',
    content: () => (
      <div>
        <h3>Reserve Your Elevator</h3>
        <p>
          Double check with your building management and verify what dates and times you can move. Reserve your elevator
          with your building management and update your Move Plan accordingly.
        </p>
        <h3>Ask if a COI is required</h3>
        <p>
          Does your building have shared spaces? You may need a Certificate of Insurance (COI). Reach out to your
          building management at both pick up and drop off locations with plenty of time ahead of your move date to see
          if a COI is required. If COI is required, you won’t be able to move without one!
        </p>
        <p>
          If a COI is required, add it to your Move Plan and include your building management\’s contact info. If your
          building management sent you a COI Sample or instructions, email this to your mover. Allow at least 2 business
          days for the movers to issue and send the requested COI.
        </p>
      </div>
    ),
    tip:
      'Reserving the elevator and notifying the moving company that a COI is required is crucial in order to make sure movers can complete your move.',
    image: '/coi_required.png'
  },

  {
    title: 'Request boxes, packing, or additional services',
    content: () => (
      <div>
        <p>If you wish to add additional services, simply go to your Move Plan and update it.</p>
        <h3>order boxes</h3>
        <p>
          If you want to pack yourself and would like boxes to be delivered to you before your move add box delivery to
          your Move Plan.
        </p>
        <h3>Packing and Unpacking</h3>
        <p>
          If you want the movers to come with boxes, pack all your stuff and even unpack it at your new places add
          Professional Packing or Professional Packing and Unpacking to your Move Plan.
        </p>
        <h3>Storage</h3>
        <p>
          If your new place is not ready to move in yet, you can store your stuff for a while. The mover will deliver
          your stuff when your new place is ready. Storage duration of up to 45 days can be added to your Move Plan.
        </p>
        <h3>Furniture special handling</h3>
        <p>
          Typical moving tasks like unscrewing legs from a sofa are included in the price of your move. Special
          handling, however, may be required for more complex needs. If you have an item that wasn’t moved in to your
          place by a mover, verify it doesn’t require a specialist or add special handling to your Move Plan.
        </p>
      </div>
    ),
    tip:
      'It is recommended to add special services to your Move Plan before your move day so mover can come prepared with the right tools and equipment. Prices vary between moving companies.',
    image: '/request_boxes.png'
  },
  {
    title: 'Request additional coverage or insurance',
    content: () => (
      <div>
        <p>If you have fragile and/or high value items, purchasing additional insurance is recommended.</p>
        <p>
          Under Federal law, interstate movers must offer two different liability options referred to as valuation
          coverage:
        </p>
        <h3>OPTION A - RELEASED VALUE (DEFAULT COVERAGE WITH NO ADDITIONAL PAYMENT)</h3>
        <p>
          The protection is minimal. Under this option, the mover assumes liability for no more than 60 cents per pound
          per article. For example, if your mover lost or damaged a 10-pound stereo component valued at $1,000, you
          would only receive $6.00 in compensation (60 cents x 10 pounds).
        </p>
        <h3>OPTION B - FULL VALUE PROTECTION (ADDITIONAL PAYMENT)</h3>
        <p>
          Under Full Value Protection, your mover is liable for the replacement value of lost or damaged goods in your
          entire shipment. If any article is lost, destroyed or damaged while in your mover's custody, your mover will,
          at its discretion, offer to do one of the following for each item:
        </p>
        <ul>
          <li>Repair the item</li>
          <li>Replace with a similar item</li>
          <li>Make a cash settlement for the cost of the repair or the current market replacement value.</li>
        </ul>
        <p>
          Under this option, movers are permitted to limit their liability for loss or damage to articles of
          extraordinary value, unless you specifically list these articles on the shipping documents. The exact cost for
          Full Value Protection varies by mover and may be subject to various deductible levels of liability that may
          reduce your cost. Ask your mover for written details of their Full Value Protection plan.
        </p>
        <p>
          <strong>Movers are only liable for boxes they packed themselves, not for boxes packed by the owner.</strong>
        </p>
        <h3>THIRD-PARTY INSURANCE</h3>
        <p>
          The cost of this insurance is not included in the basic move and must be purchased separately by you. This is
          not valuation coverage governed by Federal law—it is optional insurance regulated by State law. You have the
          option of purchasing insurance from a third-party insurance company. Before purchasing insurance, check your
          homeowner's insurance policy to see if you're already covered.
        </p>
      </div>
    ),
    tip:
      'We recommend purchasing additional coverage from your moving company or third party insurance company just in case the unexpected happens.',
    image: '/coverage.png'
  },
  {
    title: 'Review possible required services on your move day',
    content: () => (
      <div>
        <h3>POTENTIAL SERVICES REQUIRED ON YOUR MOVE DAY</h3>
        <p>Sometimes (not for every move!), additional services are required and charges may apply. Here’s when:</p>
        <ul>
          <li>
            <strong>Changes to the Inventory</strong> - You requested to move additional inventory that wasn’t entered
            into your booked Move Plan or the items that need to be moved are different than what is entered into your
            booked Move Plan
          </li>
          <li>
            <strong>Additional Services</strong> - You requested additional services when the movers arrived (example:
            ask movers to help you pack boxes)
          </li>
          <li>
            <strong>Conditions at your Location</strong> - The conditions of the job have changed (example: elevator is
            out of service and now movers must carry your furniture up and down 4 flights of stairs)
          </li>
          <li>
            <strong>Long Carry</strong> - If the distance from the truck to the building entrance is more than 200 feet,
            there might be a Long Carry fee (example: movers can’t park near your current home or new home and need to
            carry (or dolly) items for a long distance.)
          </li>
          <li>
            <strong>Wait Time</strong> - If the movers have to wait to get access to your current or new home, there
            will be a Wait Time fee (example: movers can't start the move right away because you are not ready or they
            can't access the building or your elevator reservation starts at 10:00 am but you requested for the movers
            to arrive at 8:00 am)
          </li>
          <li>
            <strong>Shuttle</strong> - If you’re moving between states and an interstate moving truck (18-wheeler) can’t
            fit into your new home’s neighborhood and a smaller moving truck is required, there will be a Shuttle Fee.
          </li>
        </ul>
      </div>
    ),
    tip:
      "These fees do not apply for every move! If you'd like additional information about any of the above fees, please don’t hesitate to contact Aqua Moving.",
    image: '/required_services.png'
  },
  {
    title: 'Update your Move Plan anytime',
    content: () => (
      <div>
        <p>
          You can update your inventory up until the day of the move.{' '}
          <strong>We recommend finalizing it two business days before your move day for accurate </strong>, but we
          understand that sometimes there's a box or two hidden away.
        </p>
        <p>
          You can download our <a href="https://itunes.apple.com/us/app/aqua/id632832637?mt=8">iPhone app</a> to update
          your inventory during your move day when your computer is packed away.
        </p>
      </div>
    ),
    tip:
      'Your price is guaranteed based on your inventory and details of your move! If you add more items, your price will increase by the same rates used on your original booking. If you end up moving less items, we will issue a refund accordingly (See Move Day! for details).',
    image: '/update_move_plan.png'
  },
  {
    title: 'Payment',
    active: false,
    content: () => (
      <div>
        <h3>PAYMENT IS PROCESSED BY AQUA MOVING. MOVER GETS PAID ONLY AFTER YOUR MOVE IS COMPLETED</h3>
        <p>
          If changes are made after your payment has already been processed, we'll bill you separately after your move
          date. (See Move Day! for details)
        </p>
        <ul>
          <li>
            <strong>Payment is processed by Aqua Moving two business days before your move</strong>
          </li>
          <li>
            <strong>Free cancellation up to two business days before your move</strong>
          </li>
          <li>
            <strong>10% cancellation fee applies if you cancel within two business days before your move</strong>
          </li>
        </ul>
        <h3>DO NOT EXCHANGE MONEY DIRECTLY WITH THE MOVER!</h3>
        <p>
          You should not exchange money with your mover (except for a tip, should you choose to give one). Billing
          occurs through Aqua Moving. Contact us as soon as you can if the movers ask for any payments during your move.
        </p>
      </div>
    ),
    tip: 'You will only be billed for the items you end up moving and for the services you actually receive!',
    image: '/payment.png'
  },
  {
    title: 'Move Day!',
    content: () => (
      <div>
        <h3>WHAT TO EXPECT?</h3>
        <p>
          You should expect your movers to show up within the estimated arrival time frame, treat your belongings with
          care, and handle your move in a courteous, respectful manner. Remember, our movers are held to a high standard
          of quality, so please contact us as soon as possible if you have any issue and note your experience in the
          Customer Feedback Survey, which will be sent to you (2 days) after your move day.
        </p>
        <h3>ANY DOCUMENTS TO SIGN?</h3>
        <p>
          You will be asked by your movers to sign a “Bill of Lading”, possibly an “Order for Service”, and an
          “Inventory list”. This paperwork gives the movers legal permission to move your belongings. As with anything,
          make sure you read everything carefully before signing, and contact us with any questions or concerns. Don’t
          forget to ask for a copy!
        </p>
        <h3>WHAT IF I HAVE MORE/LESS STUFF TO MOVE?</h3>
        <p>
          If you end up moving more/less items than noted in your original Move Plan, it should be listed and initialed
          on the “Bill of Lading” movers will present to you on your pickup location.
        </p>
        <h3>READ BEFORE YOU SIGN!</h3>
        <p>
          You should never sign that delivery has been made while you are still at the pickup location. If there are any
          changes in inventory or services, please initial to give your approval and ask if there are additional fees
          for those services, which is common.
        </p>
      </div>
    ),
    tip: 'Make sure any changes from your original Move Plan are noted and signed for on you Bill Of Lading.',
    image: '/move_day.png'
  },
  {
    title: 'Take the Aqua Moving Feedback Survey, enter to win $100 egift card',
    active: false,
    content: () => (
      <div>
        <h3>HOW WAS YOUR MOVE?</h3>
        <p>
          We will email you a Feedback Survey 2 days after your move date. Keep an eye out in your inbox and don't
          forget to share your feedback! After submitting your feedback, you will automatically be entered into our
          monthly drawing for a chance to win a $100 Target eGiftcard.
        </p>
      </div>
    ),
    tip:
      'Let us know if your experience with Aqua Moving was good or bad. We will also appreciate if you leave a review about the mover you have used to help others make the best choice for their move.',
    image: '/gift_card.png'
  }
];

export const CITY_LAT_LONGS = [
  {
    city: 'Birmingham',
    state: 'AL',
    lat: 33.5185892,
    long: -86.8103567
  },
  {
    city: 'Anchorage',
    state: 'AK',
    lat: 61.2180556,
    long: -149.9002778
  },
  {
    city: 'Phoenix',
    state: 'AZ',
    lat: 33.4483771,
    long: -112.0740373
  },
  {
    city: 'Scottsdale',
    state: 'AZ',
    lat: 33.4941704,
    long: -111.9260519
  },
  {
    city: 'Tempe',
    state: 'AZ',
    lat: 33.4255104,
    long: -111.9400054
  },
  {
    city: 'Tucson',
    state: 'AZ',
    lat: 32.2226066,
    long: -110.9747108
  },
  {
    city: 'Little Rock',
    state: 'AR',
    lat: 34.7464809,
    long: -92.2895948
  },
  {
    city: 'Fresno',
    state: 'CA',
    lat: 36.7377981,
    long: -119.7871247
  },
  {
    city: 'Irvine',
    state: 'CA',
    lat: 33.6845673,
    long: -117.8265049
  },
  {
    city: 'Los Angeles',
    state: 'CA',
    lat: 34.0522342,
    long: -118.2436849
  },
  {
    city: 'Oakland',
    state: 'CA',
    lat: 37.8043514,
    long: -122.2711639
  },
  {
    city: 'Sacramento',
    state: 'CA',
    lat: 38.5815719,
    long: -121.4943996
  },
  {
    city: 'San Diego',
    state: 'CA',
    lat: 32.715738,
    long: -117.1610838
  },
  {
    city: 'San Francisco',
    state: 'CA',
    lat: 37.7749295,
    long: -122.4194155
  },
  {
    city: 'San Jose',
    state: 'CA',
    lat: 37.3382082,
    long: -121.8863286
  },
  {
    city: 'Aurora',
    state: 'CO',
    lat: 39.7294319,
    long: -104.8319195
  },
  {
    city: 'Colorado Springs',
    state: 'CO',
    lat: 38.8338816,
    long: -104.8213634
  },
  {
    city: 'Denver',
    state: 'CO',
    lat: 39.7392358,
    long: -104.990251
  },
  {
    city: 'Hartford',
    state: 'CT',
    lat: 41.7658043,
    long: -72.6733723
  },
  {
    city: 'New Haven',
    state: 'CT',
    lat: 41.308274,
    long: -72.9278835
  },
  {
    city: 'Stamford',
    state: 'CT',
    lat: 41.0534302,
    long: -73.5387341
  },
  {
    city: 'Washington',
    state: 'DC',
    lat: 38.9071923,
    long: -77.0368707
  },
  {
    city: 'Wilmington',
    state: 'DE',
    lat: 39.744655,
    long: -75.5483909
  },
  {
    city: 'Fort Lauderdale',
    state: 'FL',
    lat: 26.1224386,
    long: -80.1373174
  },
  {
    city: 'Fort Myers',
    state: 'FL',
    lat: 26.640628,
    long: -81.8723084
  },
  {
    city: 'Jacksonville',
    state: 'FL',
    lat: 30.3321838,
    long: -81.655651
  },
  {
    city: 'Miami',
    state: 'FL',
    lat: 25.7616798,
    long: -80.1917902
  },
  {
    city: 'Orlando',
    state: 'FL',
    lat: 28.5383355,
    long: -81.3792365
  },
  {
    city: 'Palm Beach',
    state: 'FL',
    lat: 26.7056206,
    long: -80.0364297
  },
  {
    city: 'Pompano Beach',
    state: 'FL',
    lat: 26.2378597,
    long: -80.1247667
  },
  {
    city: 'Tampa',
    state: 'FL',
    lat: 27.950575,
    long: -82.4571776
  },
  {
    city: 'Atlanta',
    state: 'GA',
    lat: 33.7489954,
    long: -84.3879824
  },
  {
    city: 'Honolulu',
    state: 'HI',
    lat: 21.3069444,
    long: -157.8583333
  },
  {
    city: 'Idaho Falls',
    state: 'ID',
    lat: 43.4926607,
    long: -112.0407584
  },
  {
    city: 'Chicago',
    state: 'IL',
    lat: 41.8781136,
    long: -87.6297982
  },
  {
    city: 'Indianapolis',
    state: 'IN',
    lat: 39.768403,
    long: -86.158068
  },
  {
    city: 'Cedar Rapids',
    state: 'IA',
    lat: 41.9778795,
    long: -91.6656232
  },
  {
    city: 'Kansas City',
    state: 'KS',
    lat: 39.1155314,
    long: -94.6267873
  },
  {
    city: 'Wichita',
    state: 'KS',
    lat: 37.6871761,
    long: -97.330053
  },
  {
    city: 'Louisville',
    state: 'KY',
    lat: 38.2526647,
    long: -85.7584557
  },
  {
    city: 'Baton Rouge',
    state: 'LA',
    lat: 30.4514677,
    long: -91.1871466
  },
  {
    city: 'New Orleans',
    state: 'LA',
    lat: 29.9510658,
    long: -90.0715323
  },
  {
    city: 'Portland',
    state: 'ME',
    lat: 43.6590993,
    long: -70.2568189
  },
  {
    city: 'Baltimore',
    state: 'MD',
    lat: 39.2903848,
    long: -76.6121893
  },
  {
    city: 'Boston',
    state: 'MA',
    lat: 42.3600825,
    long: -71.0588801
  },
  {
    city: 'Cambridge',
    state: 'MA',
    lat: 42.3736158,
    long: -71.1097335
  },
  {
    city: 'Detroit',
    state: 'MI',
    lat: 42.331427,
    long: -83.0457538
  },
  {
    city: 'Minneapolis',
    state: 'MN',
    lat: 44.977753,
    long: -93.2650108
  },
  {
    city: 'Kansas City',
    state: 'MO',
    lat: 39.0997265,
    long: -94.5785667
  },
  {
    city: 'St Louis',
    state: 'MO',
    lat: 38.6270025,
    long: -90.1994042
  },
  {
    city: 'Omaha',
    state: 'NE',
    lat: 41.2565369,
    long: -95.9345034
  },
  {
    city: 'Las Vegas',
    state: 'NV',
    lat: 36.1699412,
    long: -115.1398296
  },
  {
    city: 'Reno',
    state: 'NV',
    lat: 39.5296329,
    long: -119.8138027
  },
  {
    city: 'Manchester',
    state: 'NH',
    lat: 42.9956397,
    long: -71.4547891
  },
  {
    city: 'Hoboken',
    state: 'NJ',
    lat: 40.7439905,
    long: -74.0323626
  },
  {
    city: 'Jersey City',
    state: 'NJ',
    lat: 40.7177545,
    long: -74.0431435
  },
  {
    city: 'Newark',
    state: 'NJ',
    lat: 40.735657,
    long: -74.1723667
  },
  {
    city: 'Albuquerque',
    state: 'NM',
    lat: 35.0843859,
    long: -106.650422
  },
  {
    city: 'New York',
    state: 'NY',
    lat: 40.755931,
    long: -73.984606
  },
  {
    city: 'Charlotte',
    state: 'NC',
    lat: 35.2270869,
    long: -80.8431267
  },
  {
    city: 'Raleigh',
    state: 'NC',
    lat: 35.7795897,
    long: -78.6381787
  },
  {
    city: 'Fargo',
    state: 'ND',
    lat: 46.8771863,
    long: -96.7898034
  },
  {
    city: 'Cincinnati',
    state: 'OH',
    lat: 39.1031182,
    long: -84.5120196
  },
  {
    city: 'Cleveland',
    state: 'OH',
    lat: 41.49932,
    long: -81.6943605
  },
  {
    city: 'Columbus',
    state: 'OH',
    lat: 39.9611755,
    long: -82.9987942
  },
  {
    city: 'Tulsa',
    state: 'OK',
    lat: 36.1539816,
    long: -95.992775
  },
  {
    city: 'Portland',
    state: 'OR',
    lat: 45.5154586,
    long: -122.6793461
  },
  {
    city: 'Philadelphia',
    state: 'PA',
    lat: 39.9525839,
    long: -75.1652215
  },
  {
    city: 'Pittsburgh',
    state: 'PA',
    lat: 40.4406248,
    long: -79.9958864
  },
  {
    city: 'Providence',
    state: 'RI',
    lat: 41.8239891,
    long: -71.4128343
  },
  {
    city: 'Charleston',
    state: 'SC',
    lat: 32.7764749,
    long: -79.9310512
  },
  {
    city: 'Knoxville',
    state: 'TN',
    lat: 35.9606384,
    long: -83.9207392
  },
  {
    city: 'Memphis',
    state: 'TN',
    lat: 35.1495343,
    long: -90.0489801
  },
  {
    city: 'Nashville',
    state: 'TN',
    lat: 36.1626638,
    long: -86.7816016
  },
  {
    city: 'Austin',
    state: 'TX',
    lat: 30.267153,
    long: -97.7430608
  },
  {
    city: 'Dallas',
    state: 'TX',
    lat: 32.7766642,
    long: -96.7969879
  },
  {
    city: 'Houston',
    state: 'TX',
    lat: 29.7604267,
    long: -95.3698028
  },
  {
    city: 'San Antonio',
    state: 'TX',
    lat: 29.4241219,
    long: -98.4936282
  },
  {
    city: 'Salt Lake City',
    state: 'UT',
    lat: 40.7607793,
    long: -111.8910474
  },
  {
    city: 'Burlington',
    state: 'VT',
    lat: 44.4758825,
    long: -73.212072
  },
  {
    city: 'Arlington',
    state: 'VA',
    lat: 38.8799697,
    long: -77.1067698
  },
  {
    city: 'Richmond',
    state: 'VA',
    lat: 37.5407246,
    long: -77.4360481
  },
  {
    city: 'Virginia Beach',
    state: 'VA',
    lat: 36.8529263,
    long: -75.977985
  },
  {
    city: 'Seattle',
    state: 'WA',
    lat: 47.6062095,
    long: -122.3320708
  },
  {
    city: 'Tacoma',
    state: 'WA',
    lat: 47.2528768,
    long: -122.4442906
  },
  {
    city: 'Green Bay',
    state: 'WI',
    lat: 44.5133188,
    long: -88.0132958
  },
  {
    city: 'Madison',
    state: 'WI',
    lat: 43.0730517,
    long: -89.4012302
  },
  {
    city: 'Milwaukee',
    state: 'WI',
    lat: 43.0389025,
    long: -87.9064736
  },
  {
    city: 'New York',
    state: 'NY',
    lat: 40.7128,
    long: -74.0061
  },
  {
    city: 'New York',
    state: 'NY',
    lat: 40.7829,
    long: -73.965
  }
];

export const JSON_LD_DATA = {
  '@id': 'https://www.aqua.com',
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'New York',
    addressRegion: 'NY',
    addressCountry: 'US',
    postalCode: '10001',
    streetAddress: '555 W 25th St'
  },
  image: ['https://api.aqua.com/client_assets/images/home/facebook-social-homepage.jpg'],
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: '4.2',
    reviewCount: '36'
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 40.750142,
    longitude: -74.005266
  },
  name: 'Aqua Moving',
  description:
    'Save time and money! Instantly compare moving quotes and guaranteed prices from the best moving companies online, with real customer reviews.',
  openingHoursSpecification: [
    {
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      opens: '09:00',
      closes: '18:00'
    },
    {
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: ['Saturday', 'Sunday'],
      opens: '09:00',
      closes: '15:00'
    }
  ],
  telephone: '(855) 286-7258',
  url: 'https://www.aqua.com'
};

export const SAME_CITY_DIFF_STATE = ['Kansas City', 'Portland'];
