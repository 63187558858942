import React from 'react';
import { connect } from 'react-redux';
import { openModal } from '../../../redux/actions/modals';
import has from 'lodash-es/has';
import { signOut } from '../../../redux/actions/auth';
// import { Link } from 'react-router-dom';

const mapStateToProps = ({ auth }) => ({
  isLoggedIn: has(auth, 'user'),
  username: has(auth, 'user') ? auth.user.email : undefined,
  isAdmin: auth.isAdmin || auth.isSuperAdmin
});

const NavRight = props => {
  // const [openDropdown, setOpenDropdown] = useState(false);
  // const handleDropdownToggle = () => setOpenDropdown(!openDropdown);
  // const { isLoggedIn, openModal, username, signOut, isAdmin } = props;
  const { isLoggedIn, openModal } = props;
  return (
    <div className="for-providers proxima">
      <ul className="mb-0">
        {/* <li className="menu-item text-white mr-3">
          <a
            href={`${process.env.PUBLIC_URL}/service-providers/`}
            className="text-white menu-item-link">
            {' '}
            For Service Providers
          </a>
        </li>
        <li className="menu-item"></li> */}
      </ul>
      {isLoggedIn ? (
        <div className="users-section user-menu">
          {/* <div className="user-toggle" onMouseLeave={handleDropdownToggle} onMouseEnter={handleDropdownToggle}>
            <span className="user-name">{username}</span>
            {openDropdown && (
              <ul className="list-inline dropdown-list">
                <li className="dropdown-menu-item">
                  <a href={`${process.env.REACT_APP_SUPPORT_URL}/my-home`} className="dropdown-menu-item-link"
                  onClick={() => setOpenDropdown(false)}>
                    My moves
                  </a>
                </li>
                <li className="dropdown-menu-item">
                  <Link className="dropdown-menu-item-link benefits-link" to="/benefits/"
                  onClick={() => setOpenDropdown(false)}>
                    Get $20
                  </Link>
                </li>
                {isAdmin && (
                  <li className="dropdown-menu-item">
                    <a className="dropdown-menu-item-link" href={process.env.REACT_APP_BASE_API_URL + '/admin'}
                    onClick={() => setOpenDropdown(false)}>
                      Admin Dashboard
                    </a>
                  </li>
                )}
                <li className="dropdown-menu-item">
                  <a
                    href={`${process.env.REACT_APP_MARKETING_URL}/users/edit`}
                    className="dropdown-menu-item-link"
                    target="_self"
                    onClick={() => setOpenDropdown(false)}>
                    Account Settings
                  </a>
                </li>
                <li className="dropdown-menu-item">
                  <span className="dropdown-menu-item-link" onClick={() => signOut()}>
                    Log out
                  </span>
                </li>
              </ul>
            )}
          </div> */}
        </div>
      ) : (
        <div className="signin d-inline-block float-right">
          <ul className="d-flex justify-content-between">
            {/* <li className="text-white menu-item" ui-sref-active="active">
              <span
                className="menu-item-link"
                onClick={() => openModal({ name: 'authModal', properties: { authModalView: 'register' } })}>
                Sign Up
              </span>
            </li> */}
            <li className="text-white">
              <button
                className="primary-btn-small"
                onClick={() => openModal({ name: 'authModal', properties: { authModalView: 'login' } })}>
                Log In
              </button>
            </li>
          </ul>
        </div>
        //   <div className="d-inline-block float-right"></div>
      )}
      <style jsx>{`


        .users-section, .for-providers {
          height: 55px;
          display: flex;
        }
        .signin {
         // border-left: 2px solid #33505e;
          height: 55px;
          padding: 0 15px;
          width: 200px
        }
        .menu-item a, span {
        cursor: pointer;
        }
        .menu-item-link {
          padding: 0;
          text-decoration: none;
          color: white;
          height: 55px;
          display: block;
          margin-top: 3px;
        }
        .menu-item a:hover, span:hover {
        color: #00bee8 !important;
        }
        .user-name {
          display: inline-block;
          width: 196px;
          height: 100%;
          color: #00bee8;
          line-height: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .dropdown-list {
          width: 250px;
          position: absolute;
          top: 55px;
          right: 0;
        }
        .dropdown-menu-item {
          display: block;
          position: relative;
          line-height: 14px;
          border-width: 0;
          border-bottom: 1px inset rgba(26, 55, 73, 0.5);
          background: rgba(26, 55, 73, 0.95);
          text-align: left;
        }
        .dropdown-menu-item-link, .dropdown-menu-item > :global(a) {
          display: block;
          padding: 12px 14px;
          color: #fff;
          font-size: .8125rem;
          transition: all .25s;
        }
        .menu-item-link:hover, .dropdown-menu-item > :global(a):hover {
          text-decoration: none;
          color: #00bee8;
        }
        .user-menu {
          position: relative;
          width: 250px;
          text-align: center;
          z-index: 5;
          cursor: pointer;
          display: flex;
          justify-content: center;
        }
        .users-section {
          border-left: 1px solid #33505e;
        }
        .user-toggle:after {
          position: absolute;
          right: 15px;
          top: 25px;
          content: "";
          font-size: 10px;
          color: #00bee8;
          text-transform: none;
          background: url(${process.env.REACT_APP_CDN_IMAGES}/orange_arrow.svg);
          width: 11px;
          height 11px;
          content: "";
          background-size: 11px 8px;
          background-repeat: no-repeat;
        }
        .primary-btn-small { 
          line-height: 14px;
          margin-top: 5px;
          padding: 5px 30px;
          height: 26px;
          // background: #E33337;
          // color: white !important;
          
        }
      `}</style>
    </div>
  );
};

export default connect(mapStateToProps, { openModal, signOut })(NavRight);
