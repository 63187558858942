import React, { useEffect, lazy, Suspense } from "react";
import { connect } from 'react-redux';
import DnaForm from './DnaForm';
import CtaButton from './CtaButton';
import { setDnaError } from '../../../redux/actions/movePlan';
import has from 'lodash-es/has';

const AsyncServiceMessages = lazy(() => import("./ServiceMessages"));

const mapStateToProps = ({movePlan}) => ({
  loading: movePlan.loading,
  dnaError: has(movePlan, 'dnaError') ? movePlan.dnaError : false
});

const DnaContainer = ({withArrow, viewModel, dnaError, setDnaError}) => {
  useEffect(() => setDnaError(false), [setDnaError]);
  let arrowClass = ''
  if (withArrow) { arrowClass = 'with-arrow' }
  else { withArrow = '' }
  return (
    <section className={`sidebar-section dna-container-${viewModel} ${arrowClass} ${arrowClass}-${viewModel}`}>
      { dnaError 
        ? <Suspense fallback={''}><AsyncServiceMessages dnaError={dnaError}/></Suspense>
        : <DnaForm viewModel={viewModel}>
            <div className={`cta-button-container-${viewModel}`}>
              <CtaButton viewModel={viewModel} buttonText="Compare Movers &amp; Prices"/>
            </div>
          </DnaForm>
      }
      <style jsx>{`
        .sidebar-section {
          z-index: 2;
        }
        
        .sidebar-form-message {
          font-size: 1.15rem;
        }
        .with-arrow:before {
          content: '';
          position: fixed;
          display: block;
          background: url('${process.env.REACT_APP_CDN_IMAGES}/ic_arrow.svg') no-repeat center/cover;
          height: 55px;
          width: 65px;
          z-index: 100;
          -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .5));
          -moz-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .5));
          filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .5));
        }


        //////////// Mobile //////////

        .dna-container-mobile {
          padding: 25px 1rem 2.5rem;
          margin-top: -1rem;
        }
        .cta-button-container-mobile {
          margin-top: .1125rem;
        }
        :global(.sidebar-form-mobile label, .sidebar-form-tablet label, .sidebar-form-mobile .last-plan, .sidebar-form-tablet .last-plan) 
        {
          color: white;
          font-size: 1rem !important;
        }
        
        //////////// Tablet //////////
        .dna-container-tablet {
          padding: 25px 1rem 2.5rem;
          margin-top: -1rem;
        }
        .with-arrow-tablet::before {
          top: 420px;
          right: 255px;
        }

        //////////// Desktop ///////////
        .dna-container-desktop {
          min-width: 420px;
          padding: 6.5em 1.6em 0;
          position: fixed;
          width: 25%;
          right: 0;
          top: 0;
          bottom: 0;
          overflow-y: auto;
          border-left: 1px solid rgba(0,0,0,.25);
        }
        .with-arrow-desktop::before {
          top: 385px;
          right: 403px;
        }
        //////////// Wide ///////////
        .dna-container-wide {
          width: 540px;
          padding: 8rem 5.5rem 0;
          position: fixed;
          right: 0;
          top: 0;
          bottom: 0;
          overflow-y: auto;
          border-left: 1px solid rgba(0,0,0,.25);
        }
        .with-arrow-wide::before {
          top: 460px;
          right: 515px;
        }
      `}</style>
    </section>
  );
}

export default connect(mapStateToProps, {setDnaError})(DnaContainer)