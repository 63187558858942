import React from 'react';
import { Popover as BootstrapPopover, PopoverBody } from 'reactstrap';

class Popover extends React.Component {
  state = {
    iconHover: false,
    popoverVisible: false,
    tooltipHover: false
  };

  setIconHover = () => { this.setState({ popoverVisible: true, iconHover: true }); };
  setTooltipHover = () => { this.setState({ tooltipHover: true })};
  
  unsetIconHover = () => {
    setTimeout(() => {
      if(!this.state.tooltipHover) {
        this.setState({ popoverVisible: false });
      }
      this.setState({ iconHover: false })
    }, 500)
  };

  unsetTooltipHover = () => {
    setTimeout(() => {
      this.setState({ popoverVisible: false, toolTipHover: false, iconHover: false })
    }, 100)
  };

  render() {
    return (
      <>
        <i id={this.props.id} className="tip ques-mark" onMouseEnter={this.setIconHover} onMouseLeave={this.unsetIconHover}>
          { !!this.props.icon
            ? this.props.icon
            : <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15"><defs><filter id="filter" x="0" y="0" width="14" height="15" filterUnits="userSpaceOnUse"><feOffset result="offset" dy="1" in="SourceAlpha"/><feGaussianBlur result="blur"/><feFlood result="flood" floodColor="#fff" floodOpacity=".56"/><feComposite result="composite" operator="in" in2="blur"/><feBlend result="blend" in="SourceGraphic"/><feGaussianBlur result="blur-2" stdDeviation="1" in="SourceAlpha"/><feFlood result="flood-2" floodOpacity=".04"/><feComposite result="composite-2" operator="out" in2="blur-2"/><feOffset result="offset-2" dy="1"/><feComposite result="composite-3" operator="in" in2="SourceAlpha"/><feBlend result="blend-2" mode="multiply" in2="blend"/></filter></defs><path id="Rectangle_240_copy_4" data-name="Rectangle 240 copy 4" style={{ fill: `${this.props.defaultIconColor ? this.props.defaultIconColor : "#042b3b"}`, fillRule: "evenodd", filter: "url(#filter)"}} d="M7 0a7 7 0 1 0 7 7 7 7 0 0 0-7-7zm1.158 10.485a.173.173 0 0 1-.134-.194V5.754h-2.8v.582h.506a.134.134 0 0 1 .132.135v3.879a.134.134 0 0 1-.132.135h-.507v.6H8.7v-.6h-.542zM6.944 2.93a1.216 1.216 0 1 1-1.2 1.216 1.209 1.209 0 0 1 1.2-1.216z"/></svg>
          }
        </i>
        <BootstrapPopover
          placement={this.props.placement}
          isOpen={this.state.popoverVisible || this.props.isOpen}
          target={this.props.id}>
          <PopoverBody>
            <div onMouseEnter={this.setTooltipHover}
            onMouseLeave={this.unsetTooltipHover}>
              {this.props.children}
            </div>
          </PopoverBody>
        </BootstrapPopover>
      </>
    );
  }
}

export default Popover;
