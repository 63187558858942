import { GET_SUGGESTIONS, SELECT_SUGGESTION, SET_SUGGESTIONS, SET_SCRIPT_LOADING, SET_SELECTED_DNA_TAB, SET_PPC_NYC_SELECTED_DNA_TAB, SET_PREV_PICK_UP_ADDRESS, SET_PREV_DROP_OFF_ADDRESS } from "../actionTypes";

export const getSuggestions = (query, useExact) => ({
  type: GET_SUGGESTIONS,
  payload: { query: query, useExact: useExact }
});

export const setSuggestions = (suggestions) => ({
  type: SET_SUGGESTIONS,
  payload: suggestions
});

export const selectSuggestion = (suggestion, key) => ({
  type: SELECT_SUGGESTION,
  payload: {suggestion: suggestion, key: key}
});

export const setScriptLoading = bool => ({
  type: SET_SCRIPT_LOADING,
  payload: bool
});

export const setSelectedDNATab = ( tab ) => ({
  type: SET_SELECTED_DNA_TAB,
  payload: tab
})

export const setPpcNycSelectedDNATab = ( tab ) => ({
  type: SET_PPC_NYC_SELECTED_DNA_TAB,
  payload: tab
})  

export const setPrevPickUpAddress= ( prevPickUpAddress ) => ({
  type: SET_PREV_PICK_UP_ADDRESS,
  payload: prevPickUpAddress
})

export const setPrevDropOffAddress= ( prevDropOffAddress ) => ({
  type: SET_PREV_DROP_OFF_ADDRESS,
  payload: prevDropOffAddress
})