import React, { useEffect } from 'react';
import { useScript } from '../common/Hooks.js';

const UtilitiesConnect = ({ viewModel }) => {
  const [loaded, error] = useScript(
    'https://us-apps.utilihub.io/assets/js/widgets/iframe-resizer/iframeResizer.min.js'
  );

  useEffect(() => {
    if (document.getElementById('movinghubMainDivx')) {
      const createIframe = () => {
        var i = document.createElement('iframe');
        var isOldIE = navigator.userAgent.indexOf('MSIE') !== -1;
        i.setAttribute('src', 'https://www.utilityconnect.net/aqua?use=widget');
        i.setAttribute('scrolling', 'no');
        i.setAttribute('frameborder', '0');
        i.setAttribute('width', '100%');
        i.setAttribute('style', 'border:none;');
        i.setAttribute('id', 'utils;');
        document.getElementById('movinghubMainDivx').appendChild(i);
        window.iFrameResize({
          log: false,
          autoResize: true,
          checkOrigin: false,
          heightCalculationMethod: isOldIE ? 'max' : 'lowestElement',
          warningTimeout: 10000
        });
      };

      createIframe();

      //cleanup
      return () => {
        if (document.getElementById('utils')) {
          document.getElementById('movinghubMainDivx').removeChild(document.getElementById('utils'));
        }
      };
    }
  }, [loaded, viewModel]);

  return (
    <>
      {loaded && !error ? (
        <>
          <div className="services-container" id="movinghubMainDivx"></div>
          <div id="movinghubHandDivx"></div>
        </>
      ) : (
        <p className="text-white">Utilities widget failed to load. Please refresh and try again.</p>
      )}

      <style>
        {`

          
          `}
      </style>
    </>
  );
};

export default UtilitiesConnect;
