import React from 'react'
import NavLeft from './NavLeft'
import NavRight from './NavRight';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false }
  }

  handleServicesDropdownOpen = () => {
    this.setState({ isOpen: true })
  };

  handleServicesDropdownClose = () => {
    this.setState({ isOpen: false })
  };

  render () {
    return (
      <header className="header header-container">
        <nav className="desktop-navbar w-100">
          <NavLeft className="d-sm-none" current={this.props.current}/>
          <NavRight />
        </nav>
        { /*language=CSS*/ }
        <style jsx>{`
          .header-container { display: block;}
          .header {
            font-size: 13px;
            height: 100%;
            width: 100%;
            position: fixed;
            left: 0;
            top: 0;
            height: 70px;
            line-height: 70px;
            z-index: 1000;
            background: #76FFFE 0% 0% no-repeat padding-box;
            height: 55px;
            line-height: 55px;
          }
          .desktop-navbar {
            padding-left: 15px;
            height: 55px;
            display: flex;
            justify-content: space-between;
          }
        `}</style>
      </header>
    );}
}
