import React, { lazy, Suspense, useEffect } from 'react';
import { Redirect } from 'react-router';
import { Route, Switch, withRouter } from 'react-router-dom';
import './style/index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import './style/cssLibs/ladda.css';
import FullWidthLayout from './components/layouts/FullWidthLayout';
import AnalyticsWrapper from './components/common/AnalyticsWrapper';
import { Helmet } from 'react-helmet';
import { checkIsMobile } from './redux/actions/ui/mobile';
import { checkIsApp } from './redux/actions/auth';
import { connect } from 'react-redux';
import LoadingPage from './components/common/LoadingPage';
import { JSON_LD_DATA } from './constants';
const AsyncModals = lazy(() => import('./components/modals/Modals'));
const AsyncMovingLayout = lazy(() => import('./components/layouts/Moving'));
const AsyncAffiliatesWidget = lazy(() => import('./components/common/SidebarDna/DnaForAffiliates.jsx'));

const App = props => {
  useEffect(() => {
    const stage = process.env.REACT_APP_STAGE;
    if (
      stage === 'prod' &&
      window.location.pathname === '/' &&
      window.location.host.indexOf('www') === -1 &&
      window.location.href.indexOf('demo') === -1
    ) {
      window.location.href = 'https://admin.aquamovingnyc.com';
    }

    if (
      stage === 'dev' ||
      (window.location.href.indexOf('test') > -1 && window.location.href.indexOf('/movers/') === -1) ||
      window.location.href.indexOf('demo') > -1 ||
      (window.location.href.indexOf('exp') > -1 && window.location.href.indexOf('/movers/') === -1) ||
      (window.location.href.indexOf('api') > -1 &&
        window.location.href.indexOf('/movers/') === -1 &&
        window.location.href.indexOf('/moving-companies/') === -1) ||
      window.location.href.indexOf('/moving/') > -1 ||
      window.location.href.indexOf('netdna') > -1 ||
      window.location.href.indexOf('concierge') > -1 ||
      window.location.href.indexOf('/hourly') > 1
    ) {
      const meta = document.createElement('meta');
      meta.name = 'robots';
      meta.content = 'nofollow, noindex';
      document.getElementsByTagName('head')[0].appendChild(meta);
    }
    let badting = localStorage.getItem('ngStorage-lastUUID');
    if (!!badting) localStorage.removeItem('ngStorage-lastUUID');
    props.checkIsMobile();
    props.checkIsApp();
    return () => {
      try {
        localStorage.removeItem('persist:root');
      } catch (e) {
        return false;
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <AnalyticsWrapper>
      <Helmet>
        <meta
          name="description"
          content="Save time and money! Instantly compare moving quotes and guaranteed prices from the best moving companies online, with real customer reviews."
        />
        <link rel="canonical" href="https://www.aqua.com/" />
        <script type="application/ld+json">{JSON.stringify(JSON_LD_DATA)}</script>
        {/* <script src="https://www.googleoptimize.com/optimize.js?id=GTM-TBPVXF2"></script> */}
      </Helmet>

      <div className="font-proxima">
        <Suspense fallback={null}>
          <AsyncModals />
        </Suspense>
        <Switch>
          <Route
            path="/moving/:movingPlanId"
            render={() => (
              <Suspense fallback={null}>
                <AsyncMovingLayout />
              </Suspense>
            )}
          />
          <Route
            path="/view_move_plan/:movePlanId"
            render={({ location }) => <Redirect to={`/moving/${location.pathname.slice(16)}/plan`} />}
          />
          <Route
            path="/blog/"
            component={() => {
              if (window.location.pathname.length > 5)
                return (window.location.href = 'https://blog.aqua.com' + window.location.pathname.slice(5));
              else return (window.location.href = 'https://blog.aqua.com');
            }}
          />
          <Route
            exact
            path="/affiliates-widget/"
            render={() => (
              <Suspense fallback={<LoadingPage />}>
                <AsyncAffiliatesWidget />
              </Suspense>
            )}
          />
          <Route path="/" component={FullWidthLayout} />
        </Switch>
      </div>
    </AnalyticsWrapper>
  );
};

export default withRouter(connect(null, { checkIsMobile, checkIsApp })(App));
