import React, { useEffect, lazy, Suspense  } from 'react'
import { connect } from 'react-redux'
import Cover from './Cover';
//import Footer from '../common/Footer/Footer';
import { setLastMPID } from '../../redux/actions/auth';
import { getUtmParams } from '../../redux/actions/analytics';
import { useTimeout } from '../common/Hooks';
import { setCouponData } from '../../redux/actions/analytics';
import { withRouter } from 'react-router';
import Numbers from './Numbers';
const AsyncFeatures = lazy(() => import("./Features"));
//const AsyncHowItWorks = lazy(() => import("../cityPages/HowItWorksCityPage"));
//const AsyncNews = lazy(() => import("./News"));
//const AsyncServices = lazy(() => import("./Services"));
//const AsyncReferralSection = lazy(() => import("./ReferralSection"));
const Landing = ({ setLastMPID, viewModel, getUtmParams, match, setCouponData }) => {
  useEffect(() => {
    //const last = localStorage.getItem('ngStorage-lastUUID');
    //if(last !== null) setLastMPID(last);
  }, [setLastMPID]);

  useEffect(() => {
    sessionStorage.removeItem('newDesign');
  }, []);

  useEffect(() => {
    switch (match.params.partner) {
      case 'equiem':
        setCouponData({ code: "EQUIEM10" })
        break
      case 'nfcamenity':
        setCouponData({ code: "NFCAMENITY5" })
        break
      default:
        break
    }//eslint-disable-next-line
  }, [match]);

  useEffect(() => {
    let t = setTimeout(() => {
      if (viewModel === 'wide' || viewModel === 'desktop') {
        const backgroundjs = document.createElement('script');
        backgroundjs.setAttribute('src', 'https://harbor.naker.io/back/viewer.js');
        backgroundjs.setAttribute('data-id', 'main-cover');
        backgroundjs.setAttribute('id', 'backgroundjscontainer')
        backgroundjs.setAttribute(
          'data-back',
          '{"particle":{"direction1":{"x":100,"y":0,"z":100},"direction2":{"x":-100,"y":0,"z":-100},"life":5,"power":0.003,"texture":"https://res.cloudinary.com/naker-io/image/upload/v1569594835/vhj4y1v0zncjmkqkqcjn.png","number":1884,"colorStart":[255,255,255,0.25],"colorEnd":[20,94,96,0.11],"sizeStart":0.4,"sizeEnd":0.1},"environment":{"gradient":"radial","sensitivity":0.16,"colorStart":[10,190,232,1],"colorEnd":[104,173,208,1]}}'
        );
        document.head.appendChild(backgroundjs);
      }
    }, 4000);
    return () => {
      clearTimeout(t)
      if (!!document.getElementById('backgroundjscontainer')) document.head.removeChild(document.getElementById('backgroundjscontainer'));
    }
  }, [viewModel])

  useTimeout(() => getUtmParams(), 3500);
  return viewModel !== '' && (
    <div className="landing-container">
      <div id="main-cover" className={`scroll home-content-${viewModel}`}>
        <Cover viewModel={viewModel} partner={match.params.partner} />
      </div>
      <div className="lower-section">
      <Numbers viewModel={viewModel} />
      <Suspense fallback={''}><AsyncFeatures viewModel={viewModel} /></Suspense>
      <div className="bg-white">
         {/*    <div className="container">
            <Suspense fallback={''}><AsyncNews viewModel={viewModel} /></Suspense>
          </div>
          <Suspense fallback={''}><AsyncHowItWorks viewModel={viewModel} hideButton={true} /></Suspense>
          <Suspense fallback={''}><AsyncServices viewModel={viewModel} /></Suspense>
          <Suspense fallback={''}><AsyncReferralSection viewModel={viewModel} /></Suspense>
          <Footer /> */}
        </div>
      </div>
      <style jsx>{`
 
        .landing-container {
          background: #00bee8;
       //   background: var(--background-color);
       background:  url(${process.env.REACT_APP_CDN_IMAGES}/home-background-image.jpg) no-repeat center center/cover;
        //  height: calc(100vh - 55px);
        }
        #main-cover {
          overflow: visible !important;
        }
        .scroll {
          overflow-y: auto
        }
        .lower-section {
          position: relative;
        }
        :global(canvas) {
          display: ${viewModel === 'wide' || viewModel === 'desktop' ? 'block' : 'none'};
        }
        :global(#movinghubMainDivx iframe) {
          min-height: 250px;
          border-radius: 5px;
        }


      `}</style>
    </div>
  )
};

export default connect(null, { setLastMPID, getUtmParams, setCouponData })(withRouter(Landing))
