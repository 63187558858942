import React from 'react';

const LoadingPage = () => (
  <>
    <div className="position-relative centered-load text-center">
      <svg className="position-absolute drive-by" viewBox="0 0 190 154">
        <path
          className="st0"
          d="M64.7 35.2l4.1 3.7m-.2-3.9l-3.7 4.1M47.5 26.7l6.2 5.6m-.3-6l-5.6 6.3M36.9 44.3l9.7 8.7m-.5-9.2l-8.7 9.7M86.8 33l9.1 9.4m.2-9.2l-9.4 9m-9.9-14L80 31m-.2-3L77 31.2M37.4 63.8l3.2 2.9m-.2-3l-2.8 3.1m7.6 14l3.2 2.8m-.2-3l-2.8 3.2m104.2 32.8l11.2-.1-.1-23.1L141.4 66h-19.7m-32.1 51l43.7-.3M62.6 88.4l-.1 28.3 10.9.2m48.7-10.3l-.5-49-28.2.2"
        />
        <path className="st0" d="M128.5 73.7l8.1-.1 10 14.1M63 107.1l97.7-.6" />
        <ellipse transform="rotate(-84.549 141.443 117.278)" className="st0" cx="141.4" cy="117.3" rx="8" ry="8" />
        <ellipse transform="rotate(-84.549 81.366 117.278)" className="st0" cx="81.4" cy="117.3" rx="8" ry="8" />
        <path
          className="st0"
          d="M55.4 105.5H35.3m20.1 6.1H41.8m13.6 5.9h-8m34.1-44.4l-2.6-2.5m0 5l-2.5-2.5m0 5l-2.5-2.5m-10.1-6.7c-.7-.7-1.8-.7-2.5 0L60 70.1c-.5.5-.5 1.2 0 1.7l.8.8c.5.5 1.2.5 1.7 0l1.3-1.3c.7-.6.7-1.7 0-2.4zm2.5 2.5c-.7-.7-1.8-.7-2.5 0l-1.3 1.3c-.5.5-.5 1.2 0 1.7l.8.8c.5.5 1.2.5 1.7 0l1.3-1.3c.7-.7.7-1.8 0-2.5zm2.5 2.5c-.7-.7-1.8-.7-2.5 0L65 75.2c-.5.5-.5 1.2 0 1.7l.8.8c.5.5 1.2.5 1.7 0l1.3-1.3c.7-.6.7-1.8 0-2.5zm2.6 2.6c-.7-.7-1.8-.7-2.5 0l-1.3 1.3c-.5.5-.5 1.2 0 1.7l.8.8c.5.5 1.4.5 1.8 0l1.2-1.2c.7-.8.7-2 0-2.6zM82.3 68l2.1-2.1c.9-.9.9-2.4 0-3.4L76.8 55c-.9-.9-2.4-.9-3.4 0l-8 8c-.7.7-.7 1.8 0 2.5s1.8.7 2.5 0l5.9-5.9"
        />
        <path
          className="st0"
          d="M72.2 79l1.1 1.1c.5.5 1.4.5 1.9 0l8.3-8.3c.5-.5.5-1.2 0-1.7l-9.7-9.7m-2.4-4.1L70.1 55c-.9-.9-2.4-.9-3.4 0l-7.6 7.6c-.9.9-.9 2.4 0 3.4l2.1 2.1"
        />
        <circle className="st0" cx="72.1" cy="67.3" r="22.9" />
      </svg>
      <p className="loading">LOADING...</p>
    </div>
    <style jsx>{ `
      p {
        color: #3d8dc6;
        margin: 0;
      }
      .centered-load {
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: calc(100vh - 12rem);
        align-items: center;
        justify-content: center;
        overflow: hidden;
      }
      .st0 {
        fill: none;
        stroke: #12beeb;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }
      svg {
        width: 10rem;
        padding-right: 1rem;
      }
      .drive-by {
        animation: drive 0.5s infinite ease-in-out;
      }
      @keyframes drive {
        0% {
          top: 50%;
        }
        50% {
          top: 52%;
        }
        100% {
          top: 50%;
        }
      }
    `}</style>
  </>
);

export default LoadingPage;
