import React from 'react';
import { HOME_SIZES_SELECT } from '../../../constants';
import cx from 'classnames';

class HomeSizeSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false, selected: HOME_SIZES_SELECT[4] };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  handleSelect = option => {
    this.setState({ show: false, selected: option });
    this.props.setFieldValue('home_size_id', option.value);
  };
  handleBlur = () => {
    if (this.state.show) {
      setTimeout(() => {
        this.setState({ show: false });
      }, 200);
    }
  };
  handleClick = () => {
    this.setState({ show: !this.state.show });
  };
  render() {
    return (
      <div
        className={cx('select-dropdown', { active: this.state.show })}
        onClick={this.handleClick}
        onBlur={this.handleBlur}>
        <svg className="icon move-size">
          <use xlinkHref="#move-size" />
        </svg>
        <input style={{ display: 'none' }} />
        <div className="selection f-s-14">
          {this.state.selected.text + ' '}
          {this.state.selected.subText && (
            <>
              <br />
              <span>{this.state.selected.subText}</span>
            </>
          )}
        </div>
        {this.state.show && (
          <div className="dropdown">
            <ul className="dropdown-list list-unstyled mb-0">
              {HOME_SIZES_SELECT.map(size => (
                <li className="dropdown-option" key={size.value} onClick={() => this.handleSelect(size)}>
                  <span className="text-compact selection-text">
                    {size.text} <br />
                    {size.subText && <span className="option-subtext"> {size.subText}</span>}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
        <style jsx>{`
          .select-dropdown {
            max-width: 500px;
            color: #042b3b;
            text-align: left;
            position: relative;
          }
          .icon {
            position: absolute;
            top: 0;
            z-index: 1;
            height: 50px;
            width: 32px;
            left: 5px;
          }
          .selection {
            vertical-align: middle;
            font-size: 0.95rem;
            line-height: 1;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 50px;
            padding-left: 40px;
            padding-right: 15px;
            font-family: ProximaNova, sans-serif;
            font-weight: 200;
          }
          .selection span {
            font-size: 0.75em;
            color: #737677;
            vertical-align: top;
          }
          .dropdown {
            position: absolute;
            top: 49px;
            z-index: 99;
            background: #fff;
            width: 100%;
            border: 1px solid #d6d6d6;
            border-radius: 0 0 2px 2px;
            -webkit-transition: all 0.2s ease-in;
            -o-transition: all 0.2s ease-in;
            transition: all 0.2s ease-in;
            -ms-overflow-style: none;
            cursor: pointer;
          }
          .dropdown-option {
            -webkit-transition: background 0.2s ease-in-out;
            -o-transition: background 0.2s ease-in-out;
            transition: background 0.2s ease-in-out;
            padding: 10px 14px;
            font-size: auto;
            line-height: 14px;
            border-bottom: 1px solid #e9e9e9;
            text-decoration: none;
            min-height: inherit;
            white-space: nowrap;
            position: relative;
            font-family: ProximaNova, sans-serif;
            font-weight: 200;
          }
          .dropdown-option:hover {
            background-color: #e2e3e5;
          }
          .dropdown-option:before {
            content: '';
            display: block;
            width: 3px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: transparent;
            text-transform: none;
            -webkit-transition: background-color 0.25s;
            -o-transition: background-color 0.25s;
            transition: background-color 0.25s;
          }
          .dropdown-option:hover:before {
            background-color: #00bee8;
          }

          .selection-text {
            font-size: 0.95rem;
          }
          .option-subtext {
            font-size: 0.75rem;
            color: #a7a7a7;
          }
        `}</style>
      </div>
    );
  }
}

export default HomeSizeSelect;
