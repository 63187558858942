import React, { Suspense, lazy, useState } from 'react';
import { connect } from 'react-redux';
import Placeitron from '../../common/Placeitron';
import HomeSizeSelect from './HomeSizeSelect';
import { Formik } from 'formik';
import { addDays, addYears, format } from 'date-fns';
import { submitDNA, continueLastPlan } from '../../../redux/actions/movePlan';
import { calcTwoBusinessDays } from '../../../redux/reducers/movePlan';
// import { AquaNetworkSwitch } from '../AquaNetworkSwitch';
// import { StorageOptions } from '../../landing/StorageOptions';
import { openModal } from '../../../redux/actions/modals';
import ContinuePlanOptions from '../ContinuePlanOptions';
import { isAfter, isBefore, isSameDay } from 'date-fns';
// import MaskedInput from 'react-text-mask';

const AsyncScriptLoadContainer = lazy(() => import('../ScriptLoadContainer'));
const AsyncAquaNetworkDatepicker = lazy(() => import('../AquaNetworkDatepicker'));

const mapStateToProps = ({ auth, movePlan, common }) => ({
  isAdmin: auth.isAdmin,
  isMover: auth.isMover,
  lastMPID: movePlan.currentMPID,
  lastMovePlan: movePlan.currentPlan.details,
  scriptLoading: common.scriptLoading
});
const DnaForm = props => {
  const [extraPickup] = useState(false);
  const [extraDropoff] = useState(false);
  const [storage] = useState(false);

  // const toggleStorage = setFieldValue => {
  //   if (!storage) {
  //     setFieldValue('storage_in_transit', true);
  //     setFieldValue('move_into_storage', false)
  //   }
  //   if(!extraDropoff) setExtraDropoff(false);
  //   setStorage(!storage)
  // }
  let { viewModel, formStyle } = props;
  return (
    <Formik
      initialValues={{
        pick_up: '',
        drop_off: '',
        extra_pick_up: '',
        extra_drop_off: '',
        home_size_id: 3,
        move_date: addDays(Date.now(), 10),
        move_into_storage: false,
        storage_in_transit: false,
        storage_move_out_date: addDays(Date.now(), 11)
      }}
      validateOnChange={false}
      validateOnBlur={true}
      validate={values => {
        let errors = {};
        if (!!!values.pick_up) errors.pick_up = true;
        if (!!!values.drop_off && !values.move_into_storage) errors.drop_off = true;
        if (extraPickup && !!!values.extra_pick_up) errors.extra_pick_up = true;
        if (extraDropoff && !!!values.extra_drop_off) errors.extra_drop_off = true;
        if (!isSameDay(values.move_date, calcTwoBusinessDays()) && isBefore(values.move_date, calcTwoBusinessDays())) {
          errors.move_date = 'Move date must be at least 2 days from today';
        }
        if (isAfter(values.move_date, addYears(Date.now(), 1))) {
          errors.move_date = 'Move date must be withing 1 year';
        }
        // if (!!!values.phone && !!!values.email) {
        //   errors.need_lead = 'Please enter either an Email or Phone Number to continue'
        // } else if (!!!values.phone && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        //   errors.email = 'Invalid email address'
        // }
        return errors;
      }}
      handleSubmit={e => e.preventDefault()}
      onSubmit={(values, { errors, setSubmitting }) => {
        const payload = {
          pick_up_address: values.pick_up,
          drop_off_address: values.drop_off,
          extra_pick_up_address: values.extra_pick_up,
          extra_drop_off_address: values.extra_drop_off,
          home_size_id: values.home_size_id,
          move_date: format(values.move_date, 'MM/DD/YYYY'),
          move_into_storage: values.move_into_storage,
          storage_in_transit: values.storage_in_transit,
          address_src: 'gmaps',
          extra_pick_up_enabled: extraPickup,
          extra_drop_off_enabled: extraDropoff,
          white_label_name: 'Aqua Moving',
          source: 'Concierge'
          // contact_phone_number: values.phone,
          // contact_email_address: values.email,
          // contact_first_name: values.name
        };
        if (!!!values.extra_pick_up) delete payload.extra_pick_up;
        if (!!!values.extra_drop_off) delete payload.extra_drop_off;
        if (storage && values.storage_in_transit)
          payload.storage_move_out_date = format(values.storage_move_out_date, 'MM/DD/YYYY');
        else delete payload.storage_move_out_date;
        if (!!props.lastMPID)
          props.openModal({ name: 'continueLastPlan', properties: { position: 'top', values: payload } });
        else props.submitDNA(payload);
      }}>
      {({ values, errors, handleSubmit, handleChange, handleBlur, setFieldValue, setFieldError, touched }) => (
        <form className={`sidebar-form-${viewModel} text-left`} onSubmit={handleSubmit} noValidate>
          {formStyle === 'inline' ? (
            <ContinuePlanOptions viewModel={props.viewModel} />
          ) : viewModel === 'mobile' || viewModel === 'tablet' || !!props.lastMPID || formStyle === 'new' ? (
            ''
          ) : (
            <div
              className={
                formStyle === 'inline'
                  ? `dna-container ${viewModel} container`
                  : `sidebar-form-message marketing-page sidebar-form-message-${viewModel} pb-2 font-proxima-bold`
              }>
              <h5 className="m-0">Real Prices - Right Now</h5>
            </div>
          )}
          {touched.move_date && errors.move_date && (
            <div className="col-12 mb-2 field-error-message pt-2 pb-2">{errors.move_date}</div>
          )}

          {!!props.lastMPID && formStyle !== 'inline' && (
            <div className={`sidebar-form-row last-plan last-plan-${viewModel} pb-2`}>
              Welcome back!{' '}
              <span onClick={() => props.continueLastPlan()} className="link">
                Continue Last Move Plan
              </span>
            </div>
          )}

          <div className={formStyle === 'inline' ? 'd-flex row all-fields' : ''}>
            <Suspense fallback={<PlaceitronPlaceholder />}>
              <AsyncScriptLoadContainer
                script="google"
                src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`}
                fallback={<PlaceitronPlaceholder />}>
                <div
                  className={
                    formStyle === 'inline'
                      ? `dna-form-row dna-form-row-${viewModel} col-lg-3 col-md-12 mb-2 route-fields`
                      : `sidebar-form-row sidebar-form-row-${viewModel}`
                  }>
                  {errors.pick_up && <span className="field-error-text">{errors.pick_up}</span>}
                  <Placeitron
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    classes={'m-b-10'}
                    addressKey="pick_up"
                    error={errors.pick_up}
                    placeholder={
                      formStyle === 'inline' ? 'From: Address, city or zip' : 'Moving From: address, city, or zip'
                    }
                    exactAddress={false}
                    icon={'from-arrow'}
                    required={true}
                  />
                </div>
                {!(storage && !values.storage_in_transit) && (
                  <div
                    className={
                      formStyle === 'inline'
                        ? `dna-form-row dna-form-row-${viewModel} col-lg-3 col-md-12 mb-2 route-fields`
                        : `sidebar-form-row sidebar-form-row-${viewModel}`
                    }>
                    <div className="field-address-holder">
                      {errors.drop_off && <span className="field-error-text">{errors.drop_off}</span>}
                      <Placeitron
                        setFieldValue={setFieldValue}
                        setFieldError={setFieldError}
                        classes={'m-b-10'}
                        addressKey="drop_off"
                        error={errors.drop_off}
                        placeholder={
                          formStyle === 'inline' ? 'To: Address, city or zip' : 'Moving To: address, city, or zip'
                        }
                        exactAddress={false}
                        icon={'to-arrow'}
                        required={true}
                      />
                    </div>
                  </div>
                )}
              </AsyncScriptLoadContainer>
            </Suspense>

            <div
              className={
                formStyle === 'inline'
                  ? `col-lg-5 col-md-12 row inside-container`
                  : `sidebar-form-row sidebar-form-row-${viewModel} clearfix`
              }>
              <div
                className={
                  formStyle === 'inline'
                    ? `col-lg-7 col-md-12 col-sm-12 mb-2 date-homesize`
                    : `home-size-container-${viewModel} m-0 `
                }>
                <div className={`home-size-container-${viewModel} m-0 `}></div>
                {formStyle === 'inline' ? '' : <label className="sidebar-label mb-0">Move Size:</label>}
                <HomeSizeSelect setFieldValue={setFieldValue} />
              </div>
              <div
                className={
                  formStyle === 'inline'
                    ? `col-lg-5 col-md-12 col-sm-12 mb-2 date-homesize`
                    : `move-date-container-${viewModel}`
                }>
                {formStyle === 'inline' ? '' : <label className="sidebar-label mb-0">Move Date:</label>}
                <div className="datepicker-wrapper">
                  <Suspense fallback={<DatepickerPlaceholder />}>
                    <AsyncAquaNetworkDatepicker
                      date={values.move_date}
                      dateKey="move_date"
                      minDate={calcTwoBusinessDays()}
                      maxDate={props.isMover ? null : addYears(Date.now(), 1)}
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      error={errors.move_date}
                      triggerCallback={true}
                    />
                  </Suspense>
                </div>
              </div>
              {/* <div className={formStyle === 'inline' ? `col-lg-5 col-md-12 col-sm-12 mb-2 date-homesize` : `phone-number-container-${viewModel}`} >
                  <div className="field phone">                    
                    <MaskedInput  
                      className={`aqua-network-textbox ${ (touched.phone && errors.phone) || (errors.need_lead)  && 'field-error'}`}                    
                      // onBlur={handleBlur}
                      onChange={handleChange}
                      name="phone"
                      type="phone"
                      style={{ width: '100%', border: '1px solid #829ca1', background: '#fff', margin: '7px 0 0 0'}}
                      placeholder="Phone Number"
                      guide={true}
                      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    />                     
                    {touched.phone && errors.phone && (<span className="field-error-message message">{errors.email}</span>)}
                    {errors.need_lead  && (<span className="field-error-message message">{errors.need_lead}</span>)}
                  </div>
                </div>
                <div className={formStyle === 'inline' ? `col-lg-5 col-md-12 col-sm-12 mb-2 date-homesize` : `email-container-${viewModel}`} >
                  <div className="field email">
                    <input
                      className={`aqua-network-textbox ${ (touched.email && errors.email) || (errors.need_lead)  && 'field-error'}`}
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      // onBlur={handleBlur}
                      onChange={handleChange}
                      name="email"
                      type="email"                      
                      autoComplete="email"
                      style={{ width: '100%', border: '1px solid #829ca1', background: '#fff', margin: '7px 0 0 0'}}
                      placeholder="Email"
                      guide={false}
                    />                    
                    {touched.email && errors.email && (<span className="field-error-message message">{errors.email}</span>)}
                    {errors.need_lead  && (<span className="field-error-message message">{errors.need_lead}</span>)}
                  </div>
                </div>
                <div className={formStyle === 'inline' ? `col-lg-5 col-md-12 col-sm-12 mb-2 date-homesize` : `name-container-${viewModel}`} >
                  <div className="field name">
                    <input
                      className={`aqua-network-textbox`}
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      // onBlur={handleBlur}
                      onChange={handleChange}
                      name="name"
                      type="name"                      
                      autoComplete="name"
                      style={{ width: '100%', border: '1px solid #829ca1', background: '#fff', margin: '7px 0 0 0'}}
                      placeholder="Name (optional)"
                      guide={false}
                    />                    
                  </div>
                </div> */}
            </div>
            {formStyle === 'inline' ? (
              <div className="col-lg-1 col-md-12 cols-sm-12 mb-2 route-fields">
                <button className="primary-btn-large h-100 w-100">Start!</button>
              </div>
            ) : (
              ''
            )}
          </div>

          {/*   ///////////    If not mobile, display extra options    /////////////////  */}

          {/* {1===1 ? null : (
              <div className="sidebar-form-row switches-container show-for-medium-up">
                <AquaNetworkSwitch
                  toggle={() => setExtraPickup(!extraPickup)}
                  value={extraPickup}
                  label="Additional pick up stop" />

                {extraPickup &&
                  <div className="extra-info stop-row">
                    <div className="field-address-holder">
                      { errors.extra_pick_up && <span className="field-error-text">{errors.extra_pick_up}</span> }
                      <Placeitron
                        setFieldValue={setFieldValue}
                        setFieldError={setFieldError}
                        classes={'m-b-10'}
                        addressKey="extra_pick_up"
                        error={errors.extra_pick_up}
                        placeholder="Address, city, or zip"
                        exactAddress={false}
                        icon={"extra-stop"}
                        required={true} />
                    </div>
                  </div>
                }
                {!values.move_into_storage &&
                  <>
                    <AquaNetworkSwitch
                      toggle={() => setExtraDropoff(!extraDropoff)}
                      value={extraDropoff}
                      label="Additional drop off stop" />
                    {extraDropoff &&
                      <div className="extra-info dropoff-row">
                        <div className="field-address-holder">
                          { errors.extra_drop_off && <span className="field-error-text">{errors.extra_drop_off}</span> }
                          <Placeitron
                            setFieldValue={setFieldValue}
                            setFieldError={setFieldError}
                            classes={'m-b-10'}
                            addressKey="extra_drop_off"
                            error={errors.extra_drop_off}
                            placeholder="Address, city, or zip"
                            exactAddress={false}
                            icon={"extra-stop"}
                            required={true} />
                        </div>
                      </div>
                    }
                  </>
                }
                <AquaNetworkSwitch
                  toggle={() => toggleStorage(setFieldValue)}
                  value={storage}
                  label="Would You like to add storage?" />

                {storage &&
                  <StorageOptions
                    moveIntoStorage={values.move_into_storage}
                    storageInTransit={values.storage_in_transit}
                    storageMoveOutDate={values.storage_move_out_date}
                    moveDate={values.move_date}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError} />
                }
              </div>
            )} */}
          {props.children}
          <style jsx>{`
            :global(.date-homesize .select-dropdown) {
              max-width: 100%;
            }
            .all-fields {
              padding: 0 10px;
            }

            .field-error-text {
              color: red;
            }
            .field-error-message {
              background-color: white;
            }

            .all-fields > .route-fields,
            .date-homesize {
              padding: 0 5px !important;
            }
            .all-fields button {
              padding: 10px 0;
            }
            .lead-text {
              font-weight: bold;
              color: white;
            }
            .inside-container {
              padding: 0;
              margin: auto;
            }

            .sidebar-label {
              line-height: 1;
              font-size: 0.8125rem;
              padding: 0 0 0.2em 0.1em;
            }
            .last-plan {
              font-size: 0.98rem;
            }
            .last-plan-tablet {
              font-size: 0.8rem;
            }
            .plan-link {
              color: #00bee8;
              text-decoration: underline;
              cursor: pointer;
            }
            .sidebar-form-tablet {
            }

            .sidebar-form-row-mobile,
            .sidebar-form-row-tablet {
              padding-bottom: 0.5rem;
            }
            .home-size-container-mobile,
            .home-size-container-tablet {
              float: left;
              width: 57.5%;
            }
            .home-size-container-mobile label,
            .home-size-container-tablet label {
              padding: 0 0 5px 2px;
            }
            .move-date-container-mobile,
            .move-date-container-tablet {
              float: right;
              width: 40%;
            }
            .move-date-container-mobile label,
            .move-date-container-tablet label {
              padding: 0 0 5px 2px;
            }
            .sidebar-form-row-mobile,
            .sidebar-form-row-tablet {
              padding-bottom: 0.5rem;
            }
            .home-size-container-desktop {
              float: left;
              width: 57.5%;
            }
            .move-date-container-desktop {
              float: right;
              width: 40%;
            }
            .sidebar-form-row-desktop {
              padding-bottom: 0.7em;
            }
            .home-size-container-wide {
              float: left;
              width: 57.5%;
            }
            .move-date-container-wide {
              float: right;
              width: 40%;
            }
            .sidebar-form-row-wide {
              padding-bottom: 0.9rem;
            }
          `}</style>
        </form>
      )}
    </Formik>
  );
};

const PlaceitronPlaceholder = () => (
  <div className="placeholder">
    <div className="input-placeholder"></div>
    <div className="input-placeholder"></div>
    <style jsx>{`
      .placeholder {
        background-color: transparent;
      }
      .input-placeholder {
        background: #fff;
        height: 50px;
        margin-bottom: 0.7em;
        border: 1px solid #d6d6d6;
        border-radius: 3px;
      }
    `}</style>
  </div>
);

const DatepickerPlaceholder = () => (
  <div className="datepicker">
    <div className="datepicker-input"></div>
    <style jsx>{`
      .datepicker {
        background: transparent;
      }
      .datepicker-input {
        height: 50px;
        margin-bottom: 0.7em;
        background: #fff;
        border: 1px solid #d6d6d6;
        border-radius: 3px;
      }
    `}</style>
  </div>
);

export default connect(mapStateToProps, { submitDNA, openModal, continueLastPlan })(DnaForm);
