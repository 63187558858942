import React from 'react';
import { Link } from 'react-router-dom';

const SiteLinks = () => (
  <ul className="footer-menu row">
    {/* <li className="menu-column left">
        <Link className="menu-title" to="/what-is-aqua/">About Us</Link>
      <ul className="footer-about-menu sub-menu">
        <li>
          <Link to="/what-is-aqua/">What is Aqua Moving</Link>
        </li>
        <li>
        <Link to="/what-is-included/">What is included</Link>
        </li>
        <li>
        <Link to="/how-it-works/">How it Works</Link>
        </li>
        <li>
          <Link to="/press-page/">Press</Link>
        </li>
        <li>
          <Link to="/agreement/"> Agreement</Link>
        </li>
        <li>
          <Link to="/privacy/"> Privacy Policy</Link>
        </li>
        <li>
        <Link to="/careers/"> Careers</Link>
        </li>
      </ul>
    </li> */}
    {/* <li className="menu-column left">
      <Link className="menu-title" to="/partnership/">Partnerships</Link>
      <ul className="footer-about-menu sub-menu">
        <li>
          <Link to="/partnership/">Become a Partner</Link>
        </li>
        <li>
          <Link to="/service-providers/"> For Service Providers</Link>
        </li>
        <li>
          <Link to="/corporate-relocation/"> Corporate Relocation</Link>
        </li>
        <li>
        <a href="https://blog.aqua.com/real-estate-agent-referral-program/">For Real Estate Agents</a>
        </li>
      </ul>
    </li> */}
    {/* <li className="menu-column left">
        <Link className="menu-title" to="/moving-services/">Moving Services</Link>
      <ul className="footer-services-menu sub-menu">
        <li>
          <Link to="/moving-local/">Local Moving</Link>
        </li>
        <li>
          <Link to="/moving-long/">Interstate Moving</Link>
        </li>
        <li>
          <Link to="/moving-companies/">Local Moving Companies</Link>
        </li>
        <li>
          <Link to="/moving-truck-rental/">Moving Truck Rental</Link>
        </li>
        <li>
          <Link to="/professional-movers/">Hiring Professional Movers</Link>
        </li>
        
      </ul>
      <div className="inner-sub-menu">
        <Link className="menu-title" to="/storage-services/"> Storage Services</Link>
        <ul className="sub-menu">
          <li>
            <Link to="/storage-warehouse/"> Warehouse</Link>
          </li>
          <li>
            <Link to="/storage-self/"> Self Storage</Link>
          </li>
        </ul>
      </div>
    </li> */}
    <li className="menu-column left">
      {/* <a className="menu-title" href="https://blog.aqua.com/">Aqua Moving Blog</a>
      <ul className="footer-guides-menu sub-menu">
        <li>
          <a href="https://blog.aqua.com/category/moving-advice-tips/">Moving Tips</a>
        </li>
        <li>
          <a href="https://blog.aqua.com/category/moving-cost/">Moving Costs</a>
        </li>
        <li>
          <a href="https://blog.aqua.com/category/how-to-before-moving/">Before Moving</a>
        </li>
        <li>
          <Link to="/deals">Deals</Link>
        </li>
  
      </ul> */}
      <div className="inner-sub-menu">
        <Link className="menu-title" to="/customer-service">
          {' '}
          Customer Support
        </Link>
        <ul className="sub-menu">
          <li>
            <Link to="/customer-service/"> Contact Us</Link>
          </li>
          <li>
            <Link to="/faq/"> FAQ</Link>
          </li>
        </ul>
      </div>
    </li>
    <style jsx>{`
      * {
        margin: 0;
        padding: 0;
        cursor: pointer;
      }
      .footer-menu {
        font-size: 0.83rem;
        color: white !important;
        padding: 0 2rem;
        line-height: 20px;
        margin-bottom: 10px;
      }
      :global(.footer-menu .menu-title) {
        font-family: ProximaNovaBold, sans-serif;
        text-transform: uppercase;
        font-weight: 600;
        color: #fff;
      }
      a,
      :global(.sub-menu a) {
        color: white;
        text-decoration: none;
      }
      a:hover,
      :global(.sub-menu a:hover),
      :global(.footer-menu .menu-title:hover) {
        transition: all 0.2s ease-in;
        color: #2bbdec !important;
      }

      li {
        list-style-type: none;
      }
      .menu-column {
        padding: 10px 15px 0;
      }
      .footer-about-menu {
        margin-top: 5px;
      }
      .inner-sub-menu {
        padding-top: 10px;
      }
    `}</style>
  </ul>
);

export default SiteLinks;
