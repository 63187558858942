import React from 'react';

const SocialBar = () => (
  <div className="position-relative">
    <ul className="social-links">
      <li className="social-item">
        <a
          className="social-link"
          href="https://twitter.com/Aqua_Moving?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
          target="_blank"
          rel="noopener noreferrer">
          <svg
            version="1.1"
            x="0px"
            y="0px"
            width="26px"
            height="21px"
            viewBox="14.668 20 29.726 24.19"
            enableBackground="new 14.668 20 29.726 24.19">
            <title>twitter link</title>
            <g id="Layer_2a"></g>
            <g id="Layer_1_1a_">
              <path
                fill="#31ACEA"
                d="M43.124,23.348c-1.047,0.46-2.172,0.771-3.352,0.911c1.205-0.716,2.131-1.85,2.564-3.2
              c-1.127,0.663-2.377,1.144-3.707,1.404c-1.062-1.124-2.582-1.827-4.26-1.827c-3.225,0-5.839,2.591-5.839,5.785
              c0,0.454,0.052,0.896,0.151,1.318c-4.852-0.24-9.153-2.544-12.032-6.044c-0.503,0.854-0.791,1.848-0.791,2.908
              c0,2.008,1.031,3.778,2.598,4.815c-0.957-0.029-1.857-0.29-2.645-0.724c-0.001,0.024-0.001,0.049-0.001,0.073
              c0,2.804,2.013,5.142,4.684,5.673c-0.49,0.133-1.006,0.203-1.538,0.203c-0.376,0-0.742-0.035-1.099-0.104
              c0.743,2.299,2.898,3.972,5.453,4.019c-1.998,1.552-4.515,2.478-7.25,2.478c-0.471,0-0.936-0.027-1.393-0.082
              c2.584,1.643,5.652,2.601,8.949,2.601c10.737,0,16.61-8.815,16.61-16.462c0-0.25-0.006-0.499-0.018-0.748
              C41.352,25.528,42.34,24.509,43.124,23.348"
              />
            </g>
          </svg>
        </a>
      </li>
      <li className="social-item">
        <a
          className="social-link"
          href="https://www.facebook.com/AquaMoving/"
          target="_blank"
          rel="noopener noreferrer">
          <svg
            version="1.1"
            x="0px"
            y="0px"
            width="11px"
            height="21px"
            viewBox="23.366 20.136 13.601 27.992"
            enableBackground="new 23.366 20.136 13.601 27.992">
            <title>facebook link</title>
            <g id="Layer_2"></g>
            <g id="Layer_1_1_">
              <path
                fill="#3B589B"
                d="M32.411,48.127H26.38V34.131h-3.014v-4.824h3.014v-2.896c0-3.936,1.701-6.275,6.535-6.275h4.024v4.824
              h-2.515c-1.883,0-2.008,0.675-2.008,1.932l-0.008,2.415h4.559l-0.533,4.824h-4.023V48.127z"
              />
            </g>
          </svg>
        </a>
      </li>
      <li className="social-item">
        <a
          className="social-link"
          href="https://www.youtube.com/channel/UCjjGfxrHM8fwG58zHGACm1Q"
          target="_blank"
          rel="noopener noreferrer">
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path
              fill="#DA4B3E"
              d="M4.652 0h1.44l.988 3.702.916-3.702h1.454l-1.665 5.505v3.757h-1.431v-3.757l-1.702-5.505zm6.594 2.373c-1.119 0-1.861.74-1.861 1.835v3.349c0 1.204.629 1.831 1.861 1.831 1.022 0 1.826-.683 1.826-1.831v-3.349c0-1.069-.797-1.835-1.826-1.835zm.531 5.127c0 .372-.19.646-.532.646-.351 0-.554-.287-.554-.646v-3.179c0-.374.172-.651.529-.651.39 0 .557.269.557.651v3.179zm4.729-5.07v5.186c-.155.194-.5.512-.747.512-.271 0-.338-.186-.338-.46v-5.238h-1.27v5.71c0 .675.206 1.22.887 1.22.384 0 .918-.2 1.468-.853v.754h1.27v-6.831h-1.27zm2.203 13.858c-.448 0-.541.315-.541.763v.659h1.069v-.66c.001-.44-.092-.762-.528-.762zm-4.703.04c-.084.043-.167.109-.25.198v4.055c.099.106.194.182.287.229.197.1.485.107.619-.067.07-.092.105-.241.105-.449v-3.359c0-.22-.043-.386-.129-.5-.147-.193-.42-.214-.632-.107zm4.827-5.195c-2.604-.177-11.066-.177-13.666 0-2.814.192-3.146 1.892-3.167 6.367.021 4.467.35 6.175 3.167 6.367 2.6.177 11.062.177 13.666 0 2.814-.192 3.146-1.893 3.167-6.367-.021-4.467-.35-6.175-3.167-6.367zm-12.324 10.686h-1.363v-7.54h-1.41v-1.28h4.182v1.28h-1.41v7.54zm4.846 0h-1.21v-.718c-.223.265-.455.467-.696.605-.652.374-1.547.365-1.547-.955v-5.438h1.209v4.988c0 .262.063.438.322.438.236 0 .564-.303.711-.487v-4.939h1.21v6.506zm4.657-1.348c0 .805-.301 1.431-1.106 1.431-.443 0-.812-.162-1.149-.583v.5h-1.221v-8.82h1.221v2.84c.273-.333.644-.608 1.076-.608.886 0 1.18.749 1.18 1.631v3.609zm4.471-1.752h-2.314v1.228c0 .488.042.91.528.91.511 0 .541-.344.541-.91v-.452h1.245v.489c0 1.253-.538 2.013-1.813 2.013-1.155 0-1.746-.842-1.746-2.013v-2.921c0-1.129.746-1.914 1.837-1.914 1.161 0 1.721.738 1.721 1.914v1.656z"
            />
            <title>youtube link</title>
          </svg>
        </a>
      </li>
    </ul>

    {/* <a className="bbb"
      href="https://www.bbb.org/new-york-city/business-reviews/advertising-directory-and-guide/aqua-llc-in-new-york-ny-139111/#bbbonlineclick"
      target="_blank" rel="noopener noreferrer">
      <img alt="bbb logo" className="logo bbb" src={`${process.env.REACT_APP_CDN_IMAGES}/trustLogo_1.png`} />
    </a> */}

    <style jsx>{`
      .social-links {
        text-align: right;
      }
      .social-item {
        display: inline-block;
        width: auto;
        padding: 0.7rem;
        vertical-align: middle;
      }
      .bbb {
        position: absolute;
        right: 5px;
        top: 23px;
        background: #042b3b;
        border-radius: 7px;
      }
    `}</style>
  </div>
);

export default SocialBar;
